import { Icon } from '@nrk/yr-icons';
import { useFetchLocation } from '../../data/locations/hooks';
import { Heading } from '../Heading/Heading';
import { ISyncLocation } from './SyncLocationsFromLocalStorage';

// We need a dedicated component to prevent calling useFetch-hook in a callback
export default function SyncLocationsFromLocalStorage__ListItem({ syncLocation }: { syncLocation: ISyncLocation }) {
  const location = useFetchLocation({ locationId: syncLocation.locationId });

  return (
    <li className="sync-locations-from-local-storage__location-list-item">
      <Heading size={'5'} level={'h5'}>
        {location.data?.name}
      </Heading>
      {syncLocation.type === 'favorite' && (
        <Icon
          className="sync-locations-from-local-storage__location-list-item-star"
          id="icon-star-filled"
          size={2}
        ></Icon>
      )}
    </li>
  );
}
