import { Icon } from '@nrk/yr-icons';
import { useEffect, useState } from 'react';
import { useIsAuthenticatedUser } from '../../contexts/IsAuthenticatedUserProvider/IsAuthenticatedUserProvider';
import { useLocationList } from '../../contexts/LocationListContext/LocationListContext';
import { useYrApiUser } from '../../contexts/YrApiUserContext/YrApiUserContext';
import { getLocalStorageString } from '../../lib/helpers/localStorage';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ILocation } from '../../model/location';
import { ClickableButton } from '../Clickable/Clickable';
import { EmptyText } from '../EmptyText/EmptyText';
import { Heading } from '../Heading/Heading';
import { LocationDetailsPanel } from '../LocationDetailsPanel/LocationDetailsPanel';
import { LocationRegion } from '../LocationRegion/LocationRegion';
import { Text } from '../Text/Text';
import EditCustomLocationName from '../EditCustomLocationName/EditCustomLocationName';

interface IProps {
  location: ILocation;
}

export const PageHeader__Location = (props: IProps) => {
  const { location } = props;

  const translate = useTranslate();
  const {
    getCustomLocationName,
    favouritedLocationIds,
    addLocationToFavorites,
    removeFavouritedLocation
  } = useLocationList();

  const customLocationName = getCustomLocationName(location.id);

  const isAuthenticatedUser = useIsAuthenticatedUser();
  const { hasUserData } = useYrApiUser();

  const [showLocationDetailsPanel, setShowLocationDetailsPanel] = useState(false);

  const isFavourite = favouritedLocationIds.some(favouriteLocationId => favouriteLocationId === location.id);

  const [editLocationId, setEditLocationId] = useState<string | null>();

  function handleToggleFavourite() {
    if (isFavourite) {
      removeFavouritedLocation({
        locationId: location.id,
        locationName: location.name,
        translate
      });
    } else {
      addLocationToFavorites({
        locationId: location.id,
        locationName: location.name,
        translate
      });
    }
  }

  function handleOpenCustomName() {
    setEditLocationId(location.id);
  }

  const editLocationIdFromLocalStorage = getLocalStorageString('editLocationId');

  useEffect(() => {
    if (editLocationIdFromLocalStorage != null) {
      setEditLocationId(editLocationIdFromLocalStorage);
    }
  }, [editLocationIdFromLocalStorage]);

  const showAsPlaceholder = hasUserData === false && isAuthenticatedUser === true;

  return (
    <div className="page-header__location">
      <Heading level="h1" size="2" className="page-header__location-header" tabIndex={-1} id="location-heading">
        {showAsPlaceholder ? (
          <EmptyText
            type="span"
            height={1}
            length={customLocationName ? customLocationName.length : location.name.length}
          />
        ) : (
          <span className="page-header__location-name"> {customLocationName ?? location.name}</span>
        )}

        {isFavourite ? (
          <Icon
            className="page-header__location-star page-header__location-star--filled"
            id="icon-star-filled"
            size={2}
          />
        ) : (
          <Icon className="page-header__location-star page-header__location-star--empty" id="icon-star" size={2} />
        )}
      </Heading>
      {
        // Hack: Force VoiceOver to read the full content, by using a span with role="text"
        <Text size="5" className="page-header__location-details" role="text">
          <LocationRegion
            location={location}
            customName={customLocationName}
            showAsPlaceholder={showAsPlaceholder}
            textColor={'secondary'}
          />
        </Text>
      }

      <ClickableButton
        className="page-header__location-button"
        id="page-header__location-button"
        onClick={() => {
          setShowLocationDetailsPanel(true);
        }}
      >
        {translate('header/locationButtonLabel')}
      </ClickableButton>

      {showLocationDetailsPanel ? (
        <LocationDetailsPanel
          openedById="page-header__location-button"
          location={location}
          isFavourite={isFavourite}
          onToggleFavourite={handleToggleFavourite}
          onClose={() => {
            setShowLocationDetailsPanel(false);
          }}
          onOpenCustomName={handleOpenCustomName}
        />
      ) : null}
      {editLocationId != null && (
        <EditCustomLocationName
          trackingLabel="page-header"
          locationId={editLocationId}
          handleClose={() => {
            setEditLocationId(null);
          }}
        />
      )}
    </div>
  );
};
