import { Icon } from '@nrk/yr-icons';
import { useId } from 'react';
import ReactDOM from 'react-dom';
import { useAppState } from '../../app/contexts/AppStateContext';
import { createReturnUrl } from '../../app/urls';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { CloseButton } from '../CloseButton/CloseButton';
import { Heading } from '../Heading/Heading';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import { NRKLoginButton, TLoginInitiator } from '../NRKLoginButtons/NRKLoginButton';
import { Text } from '../Text/Text';
import './LoginModal.scss';

interface IProps {
  loginInitiator: TLoginInitiator;
  onClose: () => void;
}

export function LoginModal(props: IProps) {
  const { onClose, loginInitiator } = props;

  const localeCode = useLocaleCode();
  const { currentPage } = useAppState();
  const maskId = useId();
  const modalRoot = document.querySelector('#modalRoot');
  const translate = useTranslate();

  if (modalRoot == null) {
    return null;
  }

  const loginReturnUrl = createReturnUrl({
    localeCode,
    initiator: 'login',
    pageId: currentPage.details.pageId ?? 'home',
    subpageId: currentPage.details.params.subpageId,
    locationId: currentPage.details.params.locationId,
    routeId: currentPage.details.params.routeId
  });

  return ReactDOM.createPortal(
    <ModalDialog
      onClose={onClose}
      renderOnServer={false}
      size={'small'}
      hideHeader={true}
      hideBorder={true}
      ariaLabel={'ADD TEXT HERE'}
    >
      <div className="login-modal__close-button-contianer">
        <CloseButton as="button" buttonSize="medium" className="login-modal__close-button" onClick={onClose} />
      </div>
      <div className="login-modal__circle-container">
        <svg width="13%" height="150%" viewBox="0 0 69 356" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="-111.104" cy="175.75" r="180" fill="#1767CE" />
        </svg>
      </div>

      <div className="login-modal__promo-img-container">
        <svg
          className="login-modal__promo-img"
          role="img"
          aria-labelledby="desc"
          xmlns="http://www.w3.org/2000/svg"
          width="70%"
          viewBox="0 0 960 1020"
        >
          <desc>{translate('login/loginModal/img/ariaLabel')}</desc>
          <mask id={maskId}>
            <path
              d="M 21.6534 514.71 C 7.2178 490.63 0 469.56 0 451.5 C 0 433.44 7.2178 412.37 21.6534 388.29 C 38.495 358.19 117.289 228.76 258.037 0 L 960 0.0003 L 960 903 H 258.037 L 21.6534 514.71 Z"
              fill="#ffffff"
              fillOpacity="100%"
            />
          </mask>
          <image
            x="-25%"
            y="0"
            aria-label={translate('login/loginModal/img/ariaLabel')}
            href="/assets/images/promo_img_4.webp"
            xlinkHref="/assets/images/promo_img_4.webp"
            height="1020"
            width="150%"
            mask={`url(#${maskId})`}
          ></image>
        </svg>
      </div>
      <div className="login-modal__gradient"></div>

      <div className="login-modal">
        <Heading size={'1'} level={'h1'} className="login-modal__header">
          {translate('login/loginModal/header')}
        </Heading>
        <Text size={'4'} as="p" color="inherit" className="login-modal__list-header">
          {translate('login/loginModal/list/intro')}
        </Text>
        <ul className="login-modal__list">
          <li className="login-modal__list-item">
            <Icon id="icon-check" size={3} />
            <Text size={'4'} as="p" color="inherit">
              {translate('login/loginModal/list/customName')}
            </Text>
          </li>
          <li className="login-modal__list-item">
            <Icon id="icon-check" size={3} />
            <Text size={'4'} as="p" color="inherit">
              {translate('login/loginModal/list/sync')}
            </Text>
          </li>
          <li className="login-modal__list-item">
            <Icon id="icon-check" size={3} />
            <Text size={'4'} as="p" color="inherit">
              {translate('login/loginModal/list/customForecast')}
            </Text>
          </li>
        </ul>
        <NRKLoginButton returnUrl={loginReturnUrl} loginInitiator={loginInitiator} />
      </div>
    </ModalDialog>,
    modalRoot
  );
}
