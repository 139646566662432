import { useEffect, useRef, useState } from 'react';
import { useTimestampInterval } from '../../lib/hooks/useTimestampInterval';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Card } from '../Card/Card';
import { ClickableButton } from '../Clickable/Clickable';
import { DistanceFromLocationWithElevation } from '../DistanceFromLocationWithElevation/DistanceFromLocationWithElevation';
import { Elevation } from '../Elevation/Elevation';
import { Text } from '../Text/Text';
import { WebcamLabel } from '../WebcamLabel/WebcamLabel';
import { createWebcamUrlWithTimestamp } from '../WebcamModal/helpers';
import { IWebcamView, WebcamModal } from '../WebcamModal/WebcamModal';
import './WebcamCard.scss';

interface IProps {
  locationName?: string;
  cameraName: string;
  distance?: number;
  elevation?: number;
  source?: string;
  attribution?: string;
  views: IWebcamView[];
}

// Update the timestamp once every 5 minutes
const TIMESTAMP_INTERVAL = 5 * 60 * 1000;

export function WebcamCard(props: IProps) {
  const { locationName, cameraName, distance, elevation, source, attribution, views } = props;

  const translate = useTranslate();
  const imageRef = useRef<HTMLImageElement>(null);
  const [showModal, setShowModal] = useState<Boolean>(false);
  const timestamp = useTimestampInterval(TIMESTAMP_INTERVAL);
  const [thumbnailSize, setThumbnailSize] = useState<{ width: number; height: number }>();

  // We read the image size in the image's load event, but the image might have finished
  // loading before React was initialized so we attempt to read the image size when
  // the component is first mounted also.
  useEffect(() => {
    if (imageRef.current == null) {
      return;
    }

    const width = imageRef.current.naturalWidth;
    const height = imageRef.current.naturalHeight;

    if (width != null && height != null) {
      setThumbnailSize({ width, height });
    }
  }, []);

  const firstView = views[0];

  return (
    <Card testId="webcam-card" contentMargin={false} hoverShadow={true}>
      <div className="webcam-card">
        <img
          ref={imageRef}
          className="webcam-card__image"
          src={createWebcamUrlWithTimestamp(firstView.images.small.url, timestamp)}
          width={firstView.images.small.width}
          height={firstView.images.small.height}
          alt={firstView.description}
          loading="lazy"
          onLoad={event => {
            setThumbnailSize({
              width: event.currentTarget.naturalWidth,
              height: event.currentTarget.naturalHeight
            });
          }}
        />
        <div className="webcam-card__tint"></div>
        <div className="webcam-card__direction-label">
          <WebcamLabel
            label={firstView.direction?.short}
            // If we have more than one direction we should include how many additional views there are in the label
            value={views.length > 1 ? views.length - 1 : undefined}
            degrees={firstView.direction?.degrees}
          />
        </div>

        <ClickableButton
          onClick={() => {
            setShowModal(!showModal);
          }}
          className="webcam-card__button"
          title={`${translate('roadCameraCard/buttonTitle')}`}
          // We want the text always to be white on top of the image
          data-theme="dark"
        >
          <div className="webcam-card__text">
            <Text size="4" weight="bold" as="p" className="webcam-card__title">
              {cameraName}
            </Text>

            {elevation != null && (
              <p className="webcam-card__details">
                {distance != null && locationName != null ? (
                  <DistanceFromLocationWithElevation
                    fromLocationName={locationName}
                    meters={distance}
                    elevation={elevation}
                    textSize="5"
                  />
                ) : (
                  <Elevation textSize="5" elevation={elevation} />
                )}
              </p>
            )}
          </div>
        </ClickableButton>
      </div>

      {showModal && (
        <WebcamModal
          locationName={locationName}
          cameraName={cameraName}
          distance={distance}
          elevation={elevation}
          source={source}
          attribution={attribution}
          views={views}
          timestamp={timestamp}
          thumbnailSize={thumbnailSize}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </Card>
  );
}
