import loadable, { LoadableComponent } from '@loadable/component';
import { encode } from '../lib/helpers/url';
import { LocaleCode } from '../model/locale';
import { IPageHandler } from '../model/page';
import { IRoute } from '../model/route';
import { BirkebeinerPageHandler } from '../pages/birkebeiner/handler';
import { CoastPageHandler } from '../pages/coast/handler';
import { DetailsPageHandler } from '../pages/details/handler';
import { ExtremePageHandler } from '../pages/extreme/handler';
import { ForecastPageHandler } from '../pages/forecast/handler';
import { HomePageHandler } from '../pages/home/handler';
import { MapPageHandler } from '../pages/map/handler';
import { MountainPassPageHandler } from '../pages/mountainPass/handler';
import { OtherConditionsPageHandler } from '../pages/otherConditions/handler';
import { SearchPageHandler } from '../pages/search/handler';
import { SnowDepthsPageHandler } from '../pages/snowDepths/handler';
import { StandaloneMapPageHandler } from '../pages/standaloneMap/handler';
import { StatisticsPageHandler } from '../pages/statistics/handler';
import { TwentyOneDayForecastPageHandler } from '../pages/TwentyOneDayForecastPage/handler';
import { UpdateUserPageHandler } from '../pages/UpdateUserPage/handler';
import { UserPageHandler } from '../pages/UserPage/handler';
import { WaterTemperaturesPageHandler } from '../pages/waterTemperatures/handler';
import { WeatherWarningsPageHandler } from '../pages/weatherWarnings/handler';
import settings from './settings';

export type TResourceId = 'locationId' | 'routeId';

export function getRoutes() {
  let routes: IRoute[] = [];

  routes = routes.concat(createRoutes({ pages: pages.pages, hasUrlPath: false }));
  routes = routes.concat(createRoutes({ pages: pages.locations, resourceId: 'locationId', hasUrlPath: true }));
  routes = routes.concat(createRoutes({ pages: pages.routeForecasts, resourceId: 'routeId', hasUrlPath: true }));
  routes = routes.concat(createRoutes({ pages: pages.pagesWithUrlPath, hasUrlPath: true }));

  return routes;
}

export const pages: IPageRoutes = {
  pages: [
    {
      id: 'home',
      name: 'Home',
      path: {
        en: '',
        nb: '',
        sme: '',
        nn: ''
      },
      component: loadable(() => import('../pages/home/HomePage')),
      handler: new HomePageHandler(),
      hasIndexPage: true
    },
    {
      id: 'extreme',
      name: 'Extreme',
      path: {
        en: 'extreme',
        nb: 'ekstrem',
        nn: 'ekstrem',
        sme: 'erenoamáš'
      },
      component: loadable(() => import('../pages/extreme/ExtremePage')),
      handler: new ExtremePageHandler(),
      hasIndexPage: false,
      subpages: [
        {
          id: 'event',
          name: 'Extreme',
          path: {
            en: '*',
            nb: '*',
            sme: '*',
            nn: '*'
          }
        }
      ]
    },
    {
      id: 'search',
      name: 'Search',
      path: {
        en: 'search',
        nb: 'søk',
        nn: 'søk',
        sme: 'oza'
      },
      component: loadable(() => import('../pages/search/SearchPage')),
      handler: new SearchPageHandler(),
      hasIndexPage: true
    },
    {
      id: 'user-page',
      name: 'User Page',
      path: {
        en: 'my-page',
        nb: 'min-side',
        sme: 'mu-siidu',
        nn: 'min-side'
      },
      component: loadable(() => import('../pages/UserPage/UserPage')),
      handler: new UserPageHandler(),
      hasIndexPage: true
    },
    {
      id: 'update-user-page',
      name: 'Update User Page',
      path: {
        en: 'update-user',
        nb: 'oppdater-bruker',
        sme: 'ođasmahte-geavaheaddjir',
        nn: 'oppdater-brukar'
      },
      component: loadable(() => import('../pages/UpdateUserPage/UpdateUserPage')),
      handler: new UpdateUserPageHandler(),
      hasIndexPage: true
    },
    {
      id: 'weather-warnings',
      name: 'Weather warnings',
      path: {
        en: 'weather-warnings',
        nb: 'farevarsler',
        nn: 'farevarsel',
        sme: 'várrehusat'
      },
      component: loadable(() => import('../pages/weatherWarnings/WeatherWarningsPage')),
      handler: new WeatherWarningsPageHandler(),
      hasIndexPage: true
    },
    {
      id: 'standalone-map',
      name: 'Standalone Map',
      path: {
        en: 'map',
        nb: 'kart',
        nn: 'kart',
        sme: 'kárta'
      },
      component: loadable(() => import('../pages/standaloneMap/StandaloneMapPage')),
      handler: new StandaloneMapPageHandler(),
      hasIndexPage: false,
      subpages: [
        {
          id: 'weather',
          name: 'Standalone Forecast Map',
          path: {
            en: 'weather',
            nb: 'vær',
            nn: 'vêr',
            sme: 'dálki'
          }
        },
        {
          id: 'radar',
          name: 'Standalone Precipitation Map',
          path: {
            en: 'radar',
            nb: 'radar',
            nn: 'radar',
            sme: 'rádár'
          }
        },
        {
          id: 'wind',
          name: 'Standalone Wind Map',
          path: {
            en: 'wind',
            nb: 'vind',
            nn: 'vind',
            sme: 'biegga'
          }
        },
        {
          id: 'lightning',
          name: 'Standalone Lightning Map',
          path: {
            en: 'lightning',
            nb: 'lyn',
            nn: 'lyn',
            sme: 'álddagastá'
          }
        },
        {
          id: 'temperature',
          name: 'Standalone Temperature Map',
          path: {
            en: 'temperature',
            nb: 'temperatur',
            nn: 'temperatur',
            sme: 'temperatuvra'
          }
        }
      ]
    }
  ],
  locations: [
    {
      id: 'forecast',
      name: 'Forecast',
      path: {
        en: 'forecast',
        nb: 'værvarsel',
        nn: 'vêrvarsel',
        sme: 'dálkedieđáhus'
      },
      component: loadable(() => import('../pages/forecast/ForecastPage')),
      handler: new ForecastPageHandler(),
      hasIndexPage: false,
      subpages: [
        {
          id: 'daily-table',
          name: 'Forecast Daily Table',
          path: {
            en: 'daily-table',
            nb: 'daglig-tabell',
            nn: 'dagleg-tabell',
            sme: 'beaivválaš-tabealla'
          }
        },
        {
          id: 'graph',
          name: 'Forecast Graph',
          path: {
            en: 'graph',
            nb: 'graf',
            nn: 'graf',
            sme: 'gráfa'
          }
        },
        {
          id: 'hourly-table',
          name: 'Forecast Hourly Table',
          path: {
            en: 'hourly-table',
            nb: 'timetabell',
            nn: 'timetabell',
            sme: 'tiibmotabealla'
          }
        }
      ]
    },
    {
      id: 'details',
      name: 'Details',
      path: {
        en: 'details',
        nb: 'detaljer',
        nn: 'detaljar',
        sme: 'detáljat'
      },
      component: loadable(() => import('../pages/details/DetailsPage')),
      handler: new DetailsPageHandler(),
      hasIndexPage: false,
      subpages: [
        {
          id: 'table',
          name: 'Details Table',
          path: {
            en: 'table',
            nb: 'tabell',
            nn: 'tabell',
            sme: 'tabealla'
          }
        },
        {
          id: 'graph',
          name: 'Details Graph',
          path: {
            en: 'graph',
            nb: 'graf',
            nn: 'graf',
            sme: 'gráfa'
          }
        }
      ]
    },
    {
      id: 'coast',
      name: 'Coast',
      path: {
        en: 'coast',
        nb: 'kyst',
        nn: 'kyst',
        sme: 'riddu'
      },
      component: loadable(() => import('../pages/coast/CoastPage')),
      handler: new CoastPageHandler(),
      hasIndexPage: false,
      subpages: [
        {
          id: 'forecast',
          name: 'Coast Forecast',
          path: {
            en: 'forecast',
            nb: 'oversikt',
            nn: 'oversikt',
            sme: 'oppalašgovva'
          }
        },
        {
          id: 'hourly-table',
          name: 'Coast Hourly Table',
          path: {
            en: 'hourly-table',
            nb: 'timetabell',
            nn: 'timetabell',
            sme: 'tiibmotabealla'
          }
        },
        {
          id: 'table',
          name: 'Coast Table',
          path: {
            en: 'table',
            nb: 'tabell',
            nn: 'tabell',
            sme: 'tabealla'
          }
        },
        {
          id: 'graph',
          name: 'Coast Graph',
          path: {
            en: 'graph',
            nb: 'graf',
            nn: 'graf',
            sme: 'gráfa'
          }
        }
      ]
    },
    {
      id: 'other-conditions',
      name: 'Other Conditions',
      path: {
        en: 'other-conditions',
        nb: 'andre-varsler',
        nn: 'andre-varsel',
        sme: 'eará-várrehusat'
      },
      component: loadable(() => import('../pages/otherConditions/OtherConditions')),
      handler: new OtherConditionsPageHandler(),
      hasIndexPage: true
    },
    {
      id: '21-day-forecast',
      name: '21-day-forecast',
      path: {
        en: '21-day-forecast',
        nb: '21-dagersvarsel',
        nn: '21-dagarsvarsel',
        sme: '21-beaivvedieđáhus'
      },
      component: loadable(() => import('../pages/TwentyOneDayForecastPage/TwentyOneDayForecastPage')),
      handler: new TwentyOneDayForecastPageHandler(),
      hasIndexPage: true
    },
    {
      id: 'statistics',
      name: 'Statistics',
      path: {
        en: 'statistics',
        nb: 'historikk',
        nn: 'historikk',
        sme: 'historihkka'
      },
      query: {
        q: {
          'last-13-months': {
            en: 'last-13-months',
            nb: 'siste-13-måneder',
            nn: 'siste-13-månader',
            sme: 'maŋimus-13-mánu'
          },
          'last-30-days': {
            en: 'last-30-days',
            nb: 'siste-30-dager',
            nn: 'siste-30-dagar',
            sme: 'maŋimus-30-beaivvi'
          },
          'last-24-hours': {
            en: 'last-24-hours',
            nb: 'siste-24-timer',
            nn: 'siste-24-timer',
            sme: 'maŋimus-24-tiimmu'
          }
        }
      },
      component: loadable(() => import('../pages/statistics/StatisticsPage')),
      handler: new StatisticsPageHandler(),
      hasIndexPage: false,
      subpages: [
        {
          id: 'graph',
          name: 'Statistics Graph',
          path: {
            en: 'graph',
            nb: 'graf',
            nn: 'graf',
            sme: 'gráfa'
          }
        },
        {
          id: 'table',
          name: 'Statistics Table',
          path: {
            en: 'table',
            nb: 'tabell',
            nn: 'tabell',
            sme: 'tabealla'
          }
        }
      ]
    },
    {
      id: 'map',
      name: 'Map',
      path: {
        en: 'map',
        nb: 'kart',
        nn: 'kart',
        sme: 'kárta'
      },
      component: loadable(() => import('../pages/map/MapPage')),
      handler: new MapPageHandler(),
      hasIndexPage: false,
      subpages: [
        {
          id: 'weather',
          name: 'Forecast Map',
          path: {
            en: 'weather',
            nb: 'vær',
            nn: 'vêr',
            sme: 'dálki'
          }
        },
        {
          id: 'radar',
          name: 'Precipitation Map',
          path: {
            en: 'radar',
            nb: 'radar',
            nn: 'radar',
            sme: 'rádár'
          }
        },
        {
          id: 'wind',
          name: 'Wind Map',
          path: {
            en: 'wind',
            nb: 'vind',
            nn: 'vind',
            sme: 'biegga'
          }
        },
        {
          id: 'lightning',
          name: 'Lightning Map',
          path: {
            en: 'lightning',
            nb: 'lyn',
            nn: 'lyn',
            sme: 'álddagastá'
          }
        },
        {
          id: 'temperature',
          name: 'Temperature Map',
          path: {
            en: 'temperature',
            nb: 'temperatur',
            nn: 'temperatur',
            sme: 'temperatuvra'
          }
        }
      ]
    }
  ],
  pagesWithUrlPath: [
    {
      id: 'snow-depth-region-list',
      name: 'Snow depths region list',
      path: {
        en: 'snow-depths',
        nb: 'snødybder',
        nn: 'snødjupner',
        sme: 'muohtagassodagat'
      },
      component: loadable(() => import('../pages/snowDepths/SnowDepthsPage')),
      handler: new SnowDepthsPageHandler(),
      hasIndexPage: true,
      subpages: [
        {
          id: 'snow-depth-region',
          name: 'Snow depth region',
          path: {
            en: '*',
            nb: '*',
            sme: '*',
            nn: '*'
          }
        }
      ]
    },
    {
      id: 'water-temperatures-region-list',
      name: 'Water temperatures region list',
      path: {
        en: 'water-temperatures',
        nb: 'badetemperaturer',
        nn: 'badetemperaturar',
        sme: 'čáhcetemperatuvrrat'
      },
      component: loadable(() => import('../pages/waterTemperatures/WaterTemperaturesPage')),
      handler: new WaterTemperaturesPageHandler(),
      hasIndexPage: true,
      subpages: [
        {
          id: 'water-temperature-region',
          name: 'Water temperature region',
          path: {
            en: '*',
            nb: '*',
            sme: '*',
            nn: '*'
          }
        }
      ]
    }
  ],
  routeForecasts: [
    {
      id: 'mountain-passes',
      name: 'Mountain passes',
      path: {
        en: 'mountain-passes',
        nb: 'fjelloverganger',
        nn: 'fjellovergangar',
        sme: 'várregeainnut'
      },
      component: loadable(() => import('../pages/mountainPass/MountainPassPage')),
      handler: new MountainPassPageHandler(),
      hasIndexPage: false,
      subpages: [
        {
          id: 'south',
          name: 'Mountain pass south',
          path: {
            en: 'south',
            nb: 'sør',
            sme: 'luládat',
            nn: 'sør'
          }
        },
        {
          id: 'north',
          name: 'Mountain pass north',
          path: {
            en: 'north',
            nb: 'nord',
            sme: 'davádat',
            nn: 'nord'
          }
        }
      ]
    },
    {
      id: 'birkebeineren',
      name: 'Birkebeineren',
      path: {
        en: 'birkebeineren',
        nb: 'birkebeineren',
        nn: 'birkebeineren',
        sme: 'birkebeineren'
      },
      component: loadable(() => import('../pages/birkebeiner/BirkebeinerPage')),
      handler: new BirkebeinerPageHandler(),
      hasIndexPage: true
    }
  ]
};

function createRoutes({
  pages,
  resourceId,
  hasUrlPath
}: {
  pages: IPageRouteDefinition[];
  resourceId?: TResourceId;
  hasUrlPath: boolean;
}) {
  const routes: IRoute[] = [];
  const localeCodes = settings.locale.validCodes as LocaleCode[];

  for (const page of pages) {
    if (page.subpages != null) {
      for (const subpage of page.subpages) {
        for (const localeCode of localeCodes) {
          routes.push({
            name: subpage.name,
            pageId: page.id,
            subpageId: subpage.id,
            pagePath: page.path[localeCode],
            subpagePath: subpage.path[localeCode],
            pathTemplate: createPathTemplate({
              localeCode,
              pageId: page.path[localeCode],
              subpageId: subpage.path[localeCode],
              resourceId,
              hasUrlPath
            }),
            query: createQuery({ localeCode, query: page.query }),
            component: page.component,
            handler: page.handler,
            hasLocaleCode: true
          });
        }
      }
    }

    if (page.hasIndexPage) {
      for (const localeCode of localeCodes) {
        routes.push({
          name: page.name,
          pageId: page.id,
          pagePath: page.path[localeCode],
          pathTemplate: createPathTemplate({
            localeCode,
            pageId: page.path[localeCode],
            resourceId,
            hasUrlPath
          }),
          query: createQuery({ localeCode, query: page.query }),
          component: page.component,
          handler: page.handler,
          hasLocaleCode: true
        });
      }
    }
  }

  return routes;
}

export function createPathTemplate({
  localeCode,
  pageId,
  subpageId,
  resourceId,
  hasUrlPath
}: {
  localeCode: LocaleCode;
  pageId: string;
  subpageId?: string;
  resourceId?: 'locationId' | 'routeId';
  hasUrlPath: boolean;
}) {
  let path = `/:localeCode(${localeCode})`;

  // The home page has no page id
  if (pageId !== '') {
    path = `${path}/:pageId(${pageId})`;
  }

  if (subpageId) {
    if (subpageId === '*') {
      path = `${path}/:subpageId`;
    } else {
      path = `${path}/:subpageId(${subpageId})`;
    }
  }

  // Add resource id part to the end of the path if this route should have a resource i id
  if (resourceId != null) {
    path = `${path}/:${resourceId}?`;
  }

  if (hasUrlPath) {
    path = `${path}/:urlPath*`;
  }

  return path;
}

function createQuery({
  localeCode,
  query
}: {
  localeCode: LocaleCode;
  query?: {
    q: {
      [key: string]: {
        en: string;
        nb: string;
        sme: string;
        nn: string;
      };
    };
  };
}) {
  if (query == null) {
    return undefined;
  }

  const newQuery: IRoute['query'] = { q: {} };

  for (const [value, locales] of Object.entries(query.q)) {
    const key = encode(locales[localeCode]);

    newQuery.q[key] = value;
  }

  return newQuery;
}

interface IPageRoutes {
  pages: IPageRouteDefinition[];
  locations: IPageRouteDefinition[];
  pagesWithUrlPath: IPageRouteDefinition[];
  routeForecasts: IPageRouteDefinition[];
}

interface IPageRouteDefinition {
  id: string;
  name: string;
  path: {
    en: string;
    nb: string;
    sme: string;
    nn: string;
  };
  query?: {
    q: {
      [key: string]: {
        en: string;
        nb: string;
        sme: string;
        nn: string;
      };
    };
  };
  component: LoadableComponent<object>;
  handler: IPageHandler;
  hasIndexPage: boolean;
  subpages?: ISubpageRouteDefinition[];
}

interface ISubpageRouteDefinition {
  id: string;
  name: string;
  path: {
    en: string;
    nb: string;
    sme: string;
    nn: string;
  };
}
