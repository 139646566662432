import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useFetchUserData } from '../../data/user/hooks';
import {
  useAddLocationsToFavorites,
  useAddLocationToLastVisited,
  useDeleteCustomLocationName,
  useRemoveLocationFromFavourites,
  useRemoveLocationFromLastVisited,
  useSetCustomLocationName,
  useSyncronizeLocations,
  useReplaceLocationFavorites
} from '../../data/user/mutations';
import { TCustomName } from '../../model/api/userData';
import { ITranslateFunction } from '../../model/translate';
import { useAriaLive } from '../AriaLiveContext/AriaLiveContext';
import { useLogin } from '../LoginContext/LoginContext';
import { useToast } from '../ToastContext/ToastContext';
import { reducer } from './helpers/reducer';
import {
  getLocalStorageLocationIds,
  setLocalStorageLocationIds,
  setTotalLastVisitedAndTotalFavouritedCookies
} from './helpers/storage';
import { getUserDataLocationIds } from './helpers/userData';

interface IProps {
  initialTotalFavouritedLocations: number;
  initialTotalVisitedLocations: number;
  children: React.ReactNode;
}
interface IContext {
  isLocationListInitialized: boolean;
  initialTotalFavouritedLocations: number;
  initialTotalVisitedLocations: number;
  favouritedLocationIds: string[];
  visitedLocationIds: string[];
  prepopulatedLocationIds: string[];
  customLocationNames: TCustomName[];
  addLocationToFavorites: ({
    locationId,
    locationName,
    translate
  }: {
    locationId: string;
    locationName: string;
    translate: ITranslateFunction;
  }) => void;
  syncronizeLocations: ({
    locationIdsFavorites,
    locationIdsVisited
  }: {
    locationIdsFavorites: string[];
    locationIdsVisited: string[];
  }) => void;
  updateLocationFavorites: ({ locationIds }: { locationIds: string[] }) => void;
  removeFavouritedLocation: ({
    locationId,
    locationName,
    translate
  }: {
    locationId: string;
    locationName: string;
    translate: ITranslateFunction;
    withoutNotification?: boolean;
  }) => void;
  addVisitedLocation: (locationId: string) => void;
  removeVisitedLocation: (locationId: string) => void;
  removePrepopulatedLocation: (locationId: string) => void;
  setCustomLocationName: ({ locationId, name }: TCustomName) => void;
  deleteCustomLocationName: ({ locationId }: { locationId: string }) => void;
  getCustomLocationName: (locationdId: string) => string | undefined;
}

const LocationListContext = createContext<IContext | undefined>(undefined);

export function LocationListProvider(props: IProps) {
  const { initialTotalFavouritedLocations, initialTotalVisitedLocations, children } = props;

  const { isLoggedIn, isLoginClientInitialized, accessToken } = useLogin();
  const { data: userData, status: userDataStatus } = useFetchUserData({ accessToken });

  // TODO(AS): How should we name our mutate functions?
  const { mutate: addLocationToLastVisitedMutation } = useAddLocationToLastVisited();
  const { mutate: removeLocationFromLastVisitedMutation } = useRemoveLocationFromLastVisited();
  const { mutate: syncronizeLocationsMutation } = useSyncronizeLocations();

  const { mutate: addLocationsToFavoritesMutation } = useAddLocationsToFavorites();
  const { mutate: removeLocationFromFavoritesMutation } = useRemoveLocationFromFavourites();
  const { mutate: updateLocationFavoritesMutation } = useReplaceLocationFavorites();

  const { mutate: setCustomLocationNameMutation } = useSetCustomLocationName();
  const { mutate: deleteCustomLocationNameMutation } = useDeleteCustomLocationName();

  const { addToast } = useToast();
  const { addAriaLive } = useAriaLive();
  // We can't get isFirstRender from useAppState since this context is rendered first,
  // so we just recreate our own.
  const [isFirstRender, setIsFirstRender] = useState(true);

  // This is used to determine that the login client is initialized and update
  const [isLocationListInitialized, setIsLocationListInitialized] = useState(false);

  const [state, dispatch] = useReducer(
    reducer,
    // We need to make sure the loginClient is initialized to be sure that the user is actually not logged in
    isLoggedIn === false ? getLocalStorageLocationIds() : getUserDataLocationIds(userData)
  );

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    // If userDataStatus is `success` we can assume the user is logged in,
    // and try to render locations from LocationListContext(stored in userdata API)
    if (userDataStatus === 'success') {
      setIsLocationListInitialized(true);
    }

    // If the user is confirmed to not be logged in we will try to render locations from LocationListContext (stored in localStorage)
    if (isLoginClientInitialized && isLoggedIn === false) {
      setIsLocationListInitialized(true);
    }
  }, [userDataStatus, isLoginClientInitialized, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn === true) {
      const {
        favouritedLocationIds,
        visitedLocationIds,
        prepopulatedLocationIds,
        customLocationNames
      } = getUserDataLocationIds(userData);

      dispatch({
        type: 'UPDATE_USERDATA',
        favouritedLocationIds,
        visitedLocationIds,
        prepopulatedLocationIds,
        customLocationNames
      });
    }
  }, [userData, isLoginClientInitialized, isLoggedIn]);

  // Store locations to local storage whenever the state changes
  useEffect(() => {
    const { favouritedLocationIds, visitedLocationIds, prepopulatedLocationIds } = state;

    // We need to make sure the loginClient is initialized to be sure that the user is actually not logged in
    if (isLocationListInitialized === true) {
      if (isLoggedIn === false) {
        setLocalStorageLocationIds({ favouritedLocationIds, visitedLocationIds, prepopulatedLocationIds });
      }
      setTotalLastVisitedAndTotalFavouritedCookies({
        favouritedLocationIds,
        visitedLocationIds,
        prepopulatedLocationIds
      });
    }
  }, [state, isLocationListInitialized, isLoggedIn]);

  const getCustomLocationName = useCallback(
    (locationId: string) => {
      const customLocationName = state.customLocationNames.find(customName => customName.locationId === locationId);
      return customLocationName?.name;
    },
    [state.customLocationNames]
  );

  const addLocationToFavorites = useCallback(
    ({
      locationId,
      locationName,
      translate
    }: {
      locationId: string;
      locationName: string;
      translate: ITranslateFunction;
    }) => {
      const parsedLocationName = locationName.length > 30 ? `${locationName.slice(0, 30)}...` : locationName;
      const text = translate('notifications/locations/added', { locationName: `«${parsedLocationName}»` });

      if (isLoggedIn === true) {
        addLocationsToFavoritesMutation({ accessToken, locationIds: [locationId], dispatch, toastText: text });
      } else {
        addToast({
          type: 'NOTIFICATION',
          content: { iconId: 'icon-star-filled', text }
        });

        addAriaLive({ text });
        dispatch({ type: 'FAVOURITED_LOCATION_ADD', locationId });
      }
    },
    [accessToken, addAriaLive, addLocationsToFavoritesMutation, addToast, isLoggedIn]
  );

  const updateLocationFavorites = useCallback(
    ({ locationIds }: { locationIds: string[] }) => {
      if (isLoggedIn === true) {
        updateLocationFavoritesMutation({ accessToken, locationIds, dispatch });
      }
    },
    [accessToken, isLoggedIn, updateLocationFavoritesMutation]
  );

  const syncronizeLocations = useCallback(
    ({
      locationIdsFavorites,
      locationIdsVisited
    }: {
      locationIdsFavorites: string[];
      locationIdsVisited: string[];
    }) => {
      if (isLoggedIn === true) {
        syncronizeLocationsMutation({
          accessToken,
          locationIds: { favoriteLocationIds: locationIdsFavorites, visitedLocationIds: locationIdsVisited },
          dispatch
        });
      }
    },
    [accessToken, isLoggedIn, syncronizeLocationsMutation]
  );

  const removeFavouritedLocation = useCallback(
    ({
      locationId,
      locationName,
      translate,
      // With Stadnamn3 there are some locations that no longer exist that we will remove from favourited locations
      // When we remove these locations we do not want the Toast or AriaLive to be triggered.
      withoutNotification = false
    }: {
      locationId: string;
      locationName: string;
      translate: ITranslateFunction;
      withoutNotification?: boolean;
    }) => {
      const customName = getCustomLocationName(locationId);
      const toastLocationName = customName ?? locationName;
      const parsedLocationName =
        toastLocationName.length > 30 ? `${toastLocationName.slice(0, 30)}...` : toastLocationName;
      const text = translate('notifications/locations/removed', { locationName: `«${parsedLocationName}»` });

      if (isLoggedIn === true) {
        removeLocationFromFavoritesMutation({ accessToken, locationId, dispatch, toastText: text });
      } else {
        if (withoutNotification === false) {
          addToast({
            type: 'NOTIFICATION',
            content: { iconId: 'icon-star', text }
          });

          addAriaLive({ text });
          dispatch({ type: 'FAVOURITED_LOCATION_REMOVE', locationId });
        }
      }
    },
    [getCustomLocationName, isLoggedIn, addToast, addAriaLive, removeLocationFromFavoritesMutation, accessToken]
  );

  const addVisitedLocation = useCallback(
    (locationId: string) => {
      if (isLoggedIn === true) {
        addLocationToLastVisitedMutation({ accessToken, locationId, dispatch });
      } else {
        dispatch({ type: 'VISITED_LOCATION_ADD', locationId });
      }
    },
    [addLocationToLastVisitedMutation, isLoggedIn, accessToken]
  );

  const removeVisitedLocation = useCallback(
    (locationId: string) => {
      if (isLoggedIn === true) {
        removeLocationFromLastVisitedMutation({ accessToken, locationId, dispatch });
      } else {
        dispatch({ type: 'VISITED_LOCATION_REMOVE', locationId });
      }
    },
    [removeLocationFromLastVisitedMutation, isLoggedIn, accessToken]
  );

  const setCustomLocationName = useCallback(
    ({ locationId, name }: { locationId: string; name: string }) => {
      if (isLoggedIn === true && accessToken != null) {
        setCustomLocationNameMutation({ accessToken, locationId, name, dispatch });
      }
    },
    [isLoggedIn, accessToken, setCustomLocationNameMutation]
  );

  const deleteCustomLocationName = useCallback(
    ({ locationId }: { locationId: string }) => {
      if (isLoggedIn === true && accessToken != null) {
        deleteCustomLocationNameMutation({ accessToken, locationId, dispatch });
      }
    },
    [isLoggedIn, accessToken, deleteCustomLocationNameMutation]
  );

  const removePrepopulatedLocation = useCallback((locationId: string) => {
    dispatch({ type: 'PREPOPULATED_LOCATION_REMOVE', locationId });
  }, []);

  const value = useMemo(() => {
    // When rendering on the server we don't know what the user's locations are,
    // so we return empty lists during the first render in the browser also.
    const favouritedLocationIds = isFirstRender ? [] : state.favouritedLocationIds;
    const visitedLocationIds = isFirstRender ? [] : state.visitedLocationIds;
    const prepopulatedLocationIds = isFirstRender ? [] : state.prepopulatedLocationIds;
    const customLocationNames = isFirstRender ? [] : state.customLocationNames;

    return {
      isLocationListInitialized,
      initialTotalFavouritedLocations,
      initialTotalVisitedLocations,
      favouritedLocationIds,
      visitedLocationIds,
      prepopulatedLocationIds,
      customLocationNames,
      addLocationToFavorites,
      syncronizeLocations,
      updateLocationFavorites,
      removeFavouritedLocation,
      addVisitedLocation,
      removeVisitedLocation,
      removePrepopulatedLocation,
      setCustomLocationName,
      deleteCustomLocationName,
      getCustomLocationName
    };
  }, [
    isFirstRender,
    isLocationListInitialized,
    initialTotalFavouritedLocations,
    initialTotalVisitedLocations,
    state.favouritedLocationIds,
    state.visitedLocationIds,
    state.prepopulatedLocationIds,
    state.customLocationNames,
    addLocationToFavorites,
    syncronizeLocations,
    updateLocationFavorites,
    removeFavouritedLocation,
    addVisitedLocation,
    removeVisitedLocation,
    removePrepopulatedLocation,
    setCustomLocationName,
    deleteCustomLocationName,
    getCustomLocationName
  ]);

  return <LocationListContext.Provider value={value}>{children}</LocationListContext.Provider>;
}

export function useLocationList() {
  const context = useContext(LocationListContext);

  if (context === undefined) {
    throw new Error('useLocationList must be used within a LocationListProvider');
  }

  return context;
}
