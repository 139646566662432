import { useEffect } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { useBusyState } from '../../contexts/BusyStateContext/BusyStateContext';
import { useLocationList } from '../../contexts/LocationListContext/LocationListContext';
import { useFetchLocation } from '../../data/locations/hooks';
import { parseDecimalDegrees } from '../../lib/coordinate';
import { AppBusyStatus } from '../AppBusyStatus/AppBusyStatus';
import { AriaLive } from '../AriaLive/AriaLive';
import { ExtremeWarningsBanner } from '../ExtremeWarningsBanner/ExtremeWarningsBanner';
import { LoginPromoBanner } from '../LoginPromoBanner/LoginPromoBanner';
import { NewUserWelcomeModal } from '../NewUserWelcomeModal/NewUserWelcomeModal';
import { OfflineNotification } from '../OfflineNotification/OfflineNotification';
import { Page } from '../Page/Page';
import { PageFooter } from '../PageFooter/PageFooter';
import ServiceAnnouncement from '../ServiceAnnouncement/ServiceAnnouncement';
import { SyncLocationsFromLocalStorage } from '../SyncLocationsFromLocalStorage/SyncLocationsFromLocalStorage';
import { Toast } from '../Toast/Toast';
import './App.scss';

interface IProps {
  children: React.ReactNode;
}

export function App(props: IProps) {
  const { children } = props;

  const { previousPage, currentPage, currentPageSettings } = useAppState();
  const { addVisitedLocation } = useLocationList();
  const { isBusy } = useBusyState();

  const { showFooter } = currentPageSettings;
  const isEmbedded = currentPage.details.query.embedded;
  const previousLocationId = previousPage?.details.params.locationId;
  const currentLocationId = currentPage.details.params.locationId;

  const { data: currentLocation } = useFetchLocation({ locationId: currentLocationId });

  // Store new visited location
  useEffect(() => {
    if (previousLocationId === currentLocationId) {
      return;
    }

    if (currentLocationId == null) {
      return;
    }

    if (currentLocation == null) {
      return;
    }

    // If users navigate directly to a page with coordinate as locationId, there is a chance that the coordinates have to many decimals
    // We support coordinates with any number of decimals in the url, and Statens Vegvesen has links to Yr using five decimals.
    // However in the rest of Yr we only operate with 3 decimals, so we need to parse these coordinates before we store it in localStorage.
    // If currentLocationId is not in the format of a coordinate, we do not need to parse it.
    const position = parseDecimalDegrees(currentLocationId);
    if (position != null) {
      addVisitedLocation(currentLocation.id);
    } else {
      addVisitedLocation(currentLocation.id);
    }
  }, [addVisitedLocation, previousLocationId, currentLocationId, currentLocation]);

  return (
    <div className="app">
      <div className="app__main">
        {isEmbedded ? null : (
          <>
            <OfflineNotification />
            <ExtremeWarningsBanner />
            <ServiceAnnouncement />
            <LoginPromoBanner />
          </>
        )}

        {/**Login related modals */}
        <SyncLocationsFromLocalStorage />
        <NewUserWelcomeModal />

        <Page>{children}</Page>
      </div>

      {showFooter && (
        <div className="app__footer">
          <PageFooter />
        </div>
      )}

      <div className="app__status">
        {isBusy && <AppBusyStatus />}
        <Toast />
        <AriaLive />
      </div>
    </div>
  );
}
