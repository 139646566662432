import CoreDialog from '@nrk/core-dialog/jsx';
import classNames from 'classnames';
import noScroll from 'no-scroll';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { isAncestor } from '../../lib/element';
import { CloseButton } from '../CloseButton/CloseButton';
import './AnimatableModal.scss';

interface IProps {
  children: React.ReactNode;
  contentLoaded: boolean;
  closeButtonOnTopOfImage?: boolean;
  onClose: () => void;
  ariaLabel: string;
  showModal: boolean;
  hideCloseButton?: boolean;
  rounded?: boolean;
  withPadding?: boolean;
}

type TAnimationState = 'opening' | 'closing';

export function AnimatableModal(props: IProps) {
  const {
    children,
    closeButtonOnTopOfImage = false,
    showModal,
    contentLoaded,
    onClose,
    ariaLabel,
    hideCloseButton = false,
    rounded = false,
    withPadding = false
  } = props;

  const [animationState, setAnimationState] = useState<TAnimationState>();
  const coreDialogRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(() => {
    // trigger close animation which will trigger handleCloseTransitionEnd
    // when it is done.
    setAnimationState('closing');
    noScroll.off();
  }, []);

  // When the closing modal transition is done we call the onClose
  // method from parent.
  const handleCloseTransitionEnd = useCallback(() => {
    onClose();
  }, [onClose]);

  // We want to make sure noScroll is turned off if this component somehow is removed without closing it.
  useEffect(() => {
    return () => {
      noScroll.off();
    };
  }, []);

  // Figure out if modal should be shown turn noScroll on
  useEffect(() => {
    if (showModal) {
      noScroll.on();
    }
  }, [showModal]);

  // Make sure content (f.ex images) is loaded before animating the modal
  useEffect(() => {
    if (showModal && contentLoaded) {
      setAnimationState('opening');
    }
    return () => {};
  }, [contentLoaded, showModal]);

  // Close the dialog if the user clicks on the backdrop
  const handleDialogClick = useCallback(
    (event: React.MouseEvent) => {
      if (coreDialogRef.current == null) {
        return;
      }

      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      // Don't close the dialog if the user clicked inside the dialog
      if (isAncestor(coreDialogRef.current, event.target)) {
        return;
      }

      handleClose();
    },
    [handleClose]
  );

  if (showModal === false) {
    return null;
  }

  const modalRoot = __BROWSER__ ? document?.querySelector('#modalRoot') : undefined;
  if (modalRoot == null) {
    return null;
  }

  return ReactDOM.createPortal(
    // The `onClick` event handler on the div is only used as an extra way to close the dialog by clicking on the
    // backdrop, so it's not an accessibility-problem.
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="animatable-modal" onMouseDown={handleDialogClick}>
      <div className="animatable-modal__scroll-container">
        <div
          className={classNames('animatable-modal__flex-container', {
            'animatable-modal__flex-container--padding': withPadding
          })}
        >
          <CoreDialog
            onDialogToggle={() => {
              handleClose();
            }}
            className={classNames('animatable-modal__container', {
              'animatable-modal__container--opening': animationState === 'opening',
              'animatable-modal__container--closing': animationState === 'closing',
              'animatable-modal__container--rounded': rounded
            })}
            forwardRef={coreDialogRef}
            backdrop={'off'}
          >
            {/* To make sure the content of the modal is reachable with quick nav
            in VoiceOver, we must either have a heading or an aria-label inside the
            modal. Not all designs have a heading in the modal, so we enforce having
            an aria-label */}
            <div aria-label={ariaLabel}>
              {hideCloseButton === false && (
                <CloseButton
                  as="button"
                  className={classNames('animatable-modal__close-button', {
                    'animatable-modal__close-button--on-top-of-image': closeButtonOnTopOfImage
                  })}
                  onClick={handleClose}
                />
              )}
              {children}
            </div>
          </CoreDialog>
        </div>
      </div>
      <div
        className={classNames('animatable-modal__backdrop', {
          'animatable-modal__backdrop--opening': animationState === 'opening',
          'animatable-modal__backdrop--closing': animationState === 'closing'
        })}
      />
      <div
        className={classNames('animatable-modal__transition-end', {
          'animatable-modal__transition-end--closing': animationState === 'closing'
        })}
        onTransitionEnd={() => {
          handleCloseTransitionEnd();
        }}
      />
    </div>,
    modalRoot
  );
}
