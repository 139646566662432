import { track } from '../../lib/analytics/track';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Button } from '../Button/Button';
import { TLoginPromoBannerVariants } from './LoginPromoBanner';
import './LoginPromoBanner.scss';

interface IProps {
  colorStyle: TLoginPromoBannerVariants;
  setShowModal: (show: boolean) => void;
}

export function LoginPromoBanner__Button(props: IProps) {
  const { colorStyle, setShowModal } = props;
  const translate = useTranslate();

  return (
    <Button
      className={`login-promo-banner__button--${colorStyle}`}
      as="button"
      buttonType="default"
      buttonVariant="neutral"
      buttonSize="small"
      onClick={() => {
        track.event({ category: 'login', action: 'click_from_promo_banner', label: 'promo-banner' });
        setShowModal(true);
      }}
    >
      {translate('login/loginButton')}
    </Button>
  );
}
