import { QueryClient } from '@tanstack/react-query';
import { IPageDetails, IPageHandler } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';

export class UpdateUserPageHandler implements IPageHandler {
  getSettings() {
    return {
      isModal: false,
      showHeader: false,
      showFooter: false,
      expandedSearchInput: false
    };
  }

  getMetaData(_queryClient: QueryClient, _pageDetails: IPageDetails, translate: ITranslateFunction) {
    const title = translate('meta/updateUserPage/title');
    const ogTitle = translate('meta/updateUserPage/ogTitle');
    const description = translate('meta/updateUserPage/description');
    return {
      title: title,
      ogTitle: ogTitle,
      description: description
    };
  }
}
