import produce from 'immer';
interface IState {
  theme: 'dark' | 'light';
  prefersDarkmode: 'on' | 'off' | 'auto';
}

type TAction =
  | { type: 'SET_THEME'; theme: 'dark' | 'light' }
  | { type: 'SET_PREFERS_DARK_MODE'; prefersDarkmode: 'on' | 'off' | 'auto' };

export function reducer(state: IState, action: TAction) {
  switch (action.type) {
    case 'SET_THEME':
      return produce(state, draft => {
        draft.theme = action.theme;
      });

    case 'SET_PREFERS_DARK_MODE':
      return produce(state, draft => {
        draft.prefersDarkmode = action.prefersDarkmode;
      });

    default:
      throw new Error();
  }
}
