import { Icon } from '@nrk/yr-icons';
import { useEffect, useId, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLogin } from '../../contexts/LoginContext/LoginContext';
import { useYrApiUser } from '../../contexts/YrApiUserContext/YrApiUserContext';
import { track } from '../../lib/analytics/track';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Button } from '../Button/Button';
import { CloseButton } from '../CloseButton/CloseButton';
import { Heading } from '../Heading/Heading';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import { Text } from '../Text/Text';
import './NewUserWelcomeModal.scss';

export function NewUserWelcomeModal() {
  const maskId = useId();
  const translate = useTranslate();
  const [showNewUserWelcomeModal, setShowNewUserWelcomeModal] = useState<boolean>(false);
  const [modalRoot, setModalRoot] = useState<Element | null>();
  const { isLoggedIn } = useLogin();
  const { updateUserWelcomeMessageAccepted, name, welcomeMessageAccepted } = useYrApiUser();

  useEffect(() => {
    if (isLoggedIn === false) {
      return;
    }
    setModalRoot(document.querySelector('#modalRoot'));

    if (isLoggedIn === true && welcomeMessageAccepted === false) {
      setShowNewUserWelcomeModal(true);
    }
  }, [isLoggedIn, welcomeMessageAccepted]);

  if (modalRoot == null || showNewUserWelcomeModal === false) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalDialog
      onClose={() => {
        setShowNewUserWelcomeModal(false);
      }}
      renderOnServer={false}
      size={'small'}
      hideHeader={true}
      hideBorder={true}
      ariaLabel={translate('login/newUserWelcomeModa/ariaLabel')}
    >
      <div className="new-user-welcome-modal__close-button-contianer">
        <CloseButton
          as="button"
          buttonSize="medium"
          className="new-user-welcome-modal__close-button"
          onClick={() => {
            updateUserWelcomeMessageAccepted({ accepted: true });
            setShowNewUserWelcomeModal(false);
          }}
        />
      </div>
      <div className="new-user-welcome-modal__circle-container">
        <svg width="13%" height="150%" viewBox="0 0 69 356" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="-111.104" cy="175.75" r="180" fill="#1767CE" />
        </svg>
      </div>

      <div className="new-user-welcome-modal__promo-img-container">
        <svg
          className="new-user-welcome-modal__promo-img"
          role="img"
          aria-labelledby="desc"
          xmlns="http://www.w3.org/2000/svg"
          width="70%"
          viewBox="0 0 960 1020"
        >
          <desc>{translate('login/welcomePage/img/ariaLabel')}</desc>
          <mask id={maskId}>
            <path
              d="M 21.6534 514.71 C 7.2178 490.63 0 469.56 0 451.5 C 0 433.44 7.2178 412.37 21.6534 388.29 C 38.495 358.19 117.289 228.76 258.037 0 L 960 0.0003 L 960 903 H 258.037 L 21.6534 514.71 Z"
              fill="#ffffff"
              fillOpacity="100%"
            />
          </mask>
          <image
            x="-25%"
            y="0"
            aria-label={translate('login/welcomePage/img/ariaLabel')}
            href="/assets/images/promo_img_5.webp"
            xlinkHref="/assets/images/promo_img_5.webp"
            height="1020"
            width="150%"
            mask={`url(#${maskId})`}
          ></image>
        </svg>
      </div>
      <div className="new-user-welcome-modal__gradient"></div>

      <div className="new-user-welcome-modal">
        <Heading size={'1'} level={'h1'} className="new-user-welcome-modal__header">
          {translate('login/welcomePage/header', { name })}
        </Heading>
        <Text size={'4'} as="p" color="inherit" className="new-user-welcome-modal__list-header">
          {translate('login/welcomePage/list/intro')}
        </Text>
        <ul className="new-user-welcome-modal__list">
          <li className="new-user-welcome-modal__list-item">
            <Icon id="icon-check" size={3} />
            <Text size={'4'} as="p" color="inherit">
              {translate('login/welcomePage/list/customName')}
            </Text>
          </li>
          <li className="new-user-welcome-modal__list-item">
            <Icon id="icon-check" size={3} />
            <Text size={'4'} as="p" color="inherit">
              {translate('login/welcomePage/list/sync')}
            </Text>
          </li>
          <li className="new-user-welcome-modal__list-item">
            <Icon id="icon-check" size={3} />
            <Text size={'4'} as="p" color="inherit">
              {translate('login/welcomePage/list/customForecast')}
            </Text>
          </li>
        </ul>
        <Button
          as="button"
          buttonType="default"
          onClick={() => {
            track.event({ category: 'login', action: 'accept_welcome_message' });
            updateUserWelcomeMessageAccepted({ accepted: true });
            setShowNewUserWelcomeModal(false);
          }}
        >
          {translate('login/welcomePage/continueButton')}
        </Button>
      </div>
    </ModalDialog>,
    modalRoot
  );
}
