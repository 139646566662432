import { Icon } from '@nrk/yr-icons';
import { useAppState } from '../../app/contexts/AppStateContext';
import { translatePageUrl } from '../../app/urls';
import settings from '../../app/settings';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { isLocaleCode, LocaleCode } from '../../model/locale';
import jsCookie from 'js-cookie';
import { Text } from '../Text/Text';
import { useYrApiUser } from '../../contexts/YrApiUserContext/YrApiUserContext';
import { useLogin } from '../../contexts/LoginContext/LoginContext';

export function Menu__LanguageSelector() {
  const { currentPage } = useAppState();
  const localeCode = useLocaleCode();

  const { updateUserLanguage } = useYrApiUser();
  const { isLoggedIn } = useLogin();

  const supportedLanguages = settings.locale.supportedLanguages;

  const dropdownOptions = Object.keys(supportedLanguages).map(languageCode => {
    return (
      <option lang={supportedLanguages[languageCode as LocaleCode].lang} value={languageCode} key={languageCode}>
        {supportedLanguages[languageCode as LocaleCode].title}
      </option>
    );
  });

  function changeLanguage(event: React.ChangeEvent<HTMLSelectElement>) {
    const languageCode = event.target.value;
    const newLocaleCode: LocaleCode = isLocaleCode(languageCode) ? languageCode : 'nb';

    const url = translatePageUrl({ localeCode: newLocaleCode, page: currentPage });

    //Set locale code cookie explicitly to prevent loss of choice because of cache
    jsCookie.set(settings.cookies.locale, newLocaleCode, { expires: 365, sameSite: 'lax' });

    if (isLoggedIn === true) {
      updateUserLanguage({ language: newLocaleCode });
    }

    window.location.href = url;
  }

  return (
    <div className="menu__language-selector">
      <Icon className="menu__language-selector-icon" id="icon-chevron-down" size={2} />
      <select
        className="menu__language-selector-options"
        id="menu__language-selector-options"
        onChange={evt => {
          changeLanguage(evt);
        }}
        defaultValue={localeCode}
      >
        {dropdownOptions}
      </select>
      <label htmlFor="menu__language-selector-options">
        <Text size="4" weight="bold" lang="en-gb">
          Choose language
        </Text>
        <Text size="4" weight="bold">
          {' / '}
        </Text>
        <Text size="4" weight="bold" lang="nb-no">
          Velg språk
        </Text>
      </label>
    </div>
  );
}
