import { getRedirectLoginClient } from '@nrk/innlogging-web/redirect-login-client';
import { createBrowserHistory } from 'history';
import ms from 'ms';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider, Hydrate } from '@tanstack/react-query';
import { Root } from '../../components/Root/Root';
import { AriaLiveProvider } from '../../contexts/AriaLiveContext/AriaLiveContext';
import { BusyStateProvider } from '../../contexts/BusyStateContext/BusyStateContext';
import { LocationListProvider } from '../../contexts/LocationListContext/LocationListContext';
import { ThemeProvider } from '../../contexts/ThemeContext/ThemeContext';
import { LoginProvider } from '../../contexts/LoginContext/LoginContext';
import { ToastProvider } from '../../contexts/ToastContext/ToastContext';
import { YrApiUserProvider } from '../../contexts/YrApiUserContext/YrApiUserContext';
import { FetchResponseError } from '../errors';
import { IsAuthenticatedUserProvider } from '../../contexts/IsAuthenticatedUserProvider/IsAuthenticatedUserProvider';
import { getNrkAuthFlagCookie } from '../../lib/helpers/authCookie';

export function render({
  hydrate,
  dehydratedReactQueryState,
  initialTotalFavouritedLocations,
  initialTotalVisitedLocations,
  initialTheme,
  initialDarkmode
}: {
  hydrate: boolean;
  dehydratedReactQueryState: string;
  initialTotalFavouritedLocations: number;
  initialTotalVisitedLocations: number;
  initialTheme: 'dark' | 'light';
  initialDarkmode: 'auto' | 'on' | 'off';
}) {
  const appRoot = document.querySelector('#app-root') as HTMLElement;
  const history = createBrowserHistory();
  const loginClient = getRedirectLoginClient({ logLevel: 0 });

  // We are using defaults for staleTime and cacheTime.
  // The links under will explain the defaults we are using and how the caching will work.
  // https://tanstack.com/query/v4/docs/react/guides/important-defaults
  // https://tanstack.com/query/v4/docs/react/guides/caching
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // The server should have rendered the page with very fresh data
        // so we don't need to refetch as soon as the application is mounted.
        refetchOnMount: false,

        // React Query will always refetch data when the tab/window is refocused,
        // but we also want to refetch at an interval so Yr can be shown
        // on a screen continuously, e.g. in a hotel, and never show stale date.
        refetchInterval: ms('2m'),

        retry(failureCount, error) {
          // Response errors when fetching, as opposed to network errors,
          // should fail immediately and not get retried.
          if (error instanceof FetchResponseError) {
            return false;
          }

          // Don't retry more than twice
          return failureCount < 2;
        }
      }
    }
  });

  const content = (
    <IsAuthenticatedUserProvider nrkAuthFlag={getNrkAuthFlagCookie()}>
      <QueryClientProvider client={queryClient}>
        <LoginProvider loginClient={loginClient}>
          <YrApiUserProvider>
            <Hydrate state={dehydratedReactQueryState}>
              <BusyStateProvider>
                <AriaLiveProvider>
                  <ToastProvider>
                    <ThemeProvider initialTheme={initialTheme} initialDarkmode={initialDarkmode}>
                      <LocationListProvider
                        initialTotalFavouritedLocations={initialTotalFavouritedLocations}
                        initialTotalVisitedLocations={initialTotalVisitedLocations}
                      >
                        <Root history={history} />
                      </LocationListProvider>
                    </ThemeProvider>
                  </ToastProvider>
                </AriaLiveProvider>
              </BusyStateProvider>
            </Hydrate>
          </YrApiUserProvider>
        </LoginProvider>
      </QueryClientProvider>
    </IsAuthenticatedUserProvider>
  );

  if (hydrate) {
    hydrateRoot(appRoot, content);
  } else {
    const root = createRoot(appRoot);
    root.render(content);
  }
}
