import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IUserData } from '../../model/api/userData';
import { api } from '../api';

export function queryUserData({ accessToken }: { accessToken?: string }) {
  const queryKey = ['userData', accessToken];

  let response = null;
  async function queryFn() {
    response = await api<IUserData>({
      path: template(settings.api.paths.userData),
      accessToken
    });

    return response;
  }

  return {
    queryKey,
    queryFn
  };
}
