import settings from '../app/settings';
import Debug from 'debug';
import { ignorableErrors } from './error';
import { StringMap } from '../model/ts-utils';

// Import either the browser or the node version of Sentry.
// tslint:disable-next-line: no-var-requires

const sentry = __SERVER__ ? require('@sentry/node') : require('@sentry/browser');

const debug = Debug('yr:errorReporter');

const SUPPORTED_ENVIRONMENTS = ['production', 'staging', 'beta', 'feature-branch'];

let initialized = false;

export function initializeErrorReporter() {
  const { version } = settings;
  const { deployEnvironment, sentryApiKey, sentryProjectId } = settings.site;

  // Do not initialize Sentry if the current deploy environment is not in the list of supported environments
  if (SUPPORTED_ENVIRONMENTS.indexOf(deployEnvironment) === -1) {
    return;
  }

  sentry.init({
    dsn: `https://${sentryApiKey}@sentry.io/${sentryProjectId}`,
    release: version,
    environment: deployEnvironment,

    // Don't track sessions
    autoSessionTracking: false,

    // We don´t need to send in all errors to Sentry in production.
    // a sample rate of 0.1 means that 10% of all errors are sent to Sentry.
    // https://docs.sentry.io/error-reporting/configuration/?platform=browser#sample-rate
    sampleRate: deployEnvironment === 'production' ? 0.1 : 1,

    // Ignore known errors in the browser from badly written extensions etc.
    // We already ignore errors from this list in the unhandled middleware,
    // but we give the list to Sentry as well just to be sure
    // because if a lot of errors from this list does get reported to Sentry by mistake,
    // we will hit our quoata very quickly.
    // See https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
    ignoreErrors: __BROWSER__ ? ignorableErrors : undefined
  });

  initialized = true;
}

/**
 * Pass "captureMessage" calls on to the Sentry client.
 */
export function captureMessage(message: string, data: object) {
  if (initialized === false) {
    debug("can't send message to Sentry because Sentry has not been initialized");
    debug(message);
    debug(JSON.stringify(data));
    return;
  }

  sentry.captureMessage(message, data);
  debug('sending message to Sentry: %s', message);
}

/**
 * Pass "captureException" calls on to the Sentry client.
 * extra prop does only work on the server.
 */
export function captureException({
  error,
  logger,
  extra
}: {
  error: Error | ErrorEvent;
  logger?: string;
  extra?: StringMap<string>;
}) {
  if (initialized === false) {
    debug("can't send exception to Sentry because Sentry has not been initialized");
    debug(error);
    return;
  }
  sentry.captureException(error, { logger, extra });

  debug('sending exception to Sentry: %s', error);
}
