import { IUserData, TCustomName } from '../../../model/api/userData';

export function getUserDataLocationIds(userData?: IUserData) {
  const favouritedLocationIds: string[] = userData?.user.locations.favorites ?? [];
  const visitedLocationIds: string[] = userData?.user.locations.visited ?? [];
  const prepopulatedLocationIds: string[] = [];
  const customLocationNames: TCustomName[] = userData?.user.locations.customNames ?? [];

  return {
    favouritedLocationIds,
    visitedLocationIds,
    prepopulatedLocationIds,
    customLocationNames
  };
}
