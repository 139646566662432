import { useEffect, useState } from 'react';
import { getLocalStorageLocationIds } from '../../contexts/LocationListContext/helpers/storage';
import { useLocationList } from '../../contexts/LocationListContext/LocationListContext';
import { useLogin } from '../../contexts/LoginContext/LoginContext';
import { useFetchUserData } from '../../data/user/hooks';
import { track } from '../../lib/analytics/track';
import { getLocalStorageObject, setLocalStorageObject } from '../../lib/helpers/localStorage';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IUserData } from '../../model/api/userData';
import { AnimatableModal } from '../AnimatableModal/AnimatableModal';
import { Button } from '../Button/Button';
import { Heading } from '../Heading/Heading';
import { Text } from '../Text/Text';
import './SyncLocationsFromLocalStorage.scss';
import SyncLocationsFromLocalStorage__ListItem from './SyncLocationsFromLocalStorage__ListItem';

export interface ISyncLocation {
  locationId: string;
  type: 'visited' | 'favorite';
}

export function SyncLocationsFromLocalStorage() {
  const [showModal, setShowModal] = useState<boolean>(true);
  const [newLocations, setNewLocations] = useState<ISyncLocation[]>();

  const { isLoggedIn, accessToken } = useLogin();
  const { data: userData } = useFetchUserData({ accessToken });
  const { syncronizeLocations } = useLocationList();
  const translate = useTranslate();
  const deviceFavoritesAccepted =
    getLocalStorageObject<string[]>('locationSyncAccepted')?.includes(userData?.user.id ?? '') ?? false;

  useEffect(() => {
    if (isLoggedIn === false || userData == null) {
      return;
    }
    const localStorageFavoirteIds = getLocalStorageLocationIds().favouritedLocationIds;
    const localStorageVisitedIds = getLocalStorageLocationIds().visitedLocationIds;

    const { favorites, visited } = userData.user.locations;

    const filteredLocalStorageFavoritesFromUserData = localStorageFavoirteIds.filter(
      locationId => favorites.includes(locationId) === false
    );

    let filteredLocalStorageVisitedFromUserData = localStorageVisitedIds.filter(
      locationId => favorites.includes(locationId) === false
    );

    filteredLocalStorageVisitedFromUserData = localStorageVisitedIds.filter(
      locationId => visited.includes(locationId) === false
    );

    filteredLocalStorageVisitedFromUserData = filteredLocalStorageVisitedFromUserData.filter(
      locationId => filteredLocalStorageFavoritesFromUserData.includes(locationId) === false
    );

    const syncLocations: ISyncLocation[] = [];

    filteredLocalStorageFavoritesFromUserData.forEach(locationId => {
      syncLocations.push({ locationId, type: 'favorite' });
    });

    filteredLocalStorageVisitedFromUserData.forEach(locationId => {
      syncLocations.push({ locationId, type: 'visited' });
    });

    setShowModal(deviceFavoritesAccepted === false && syncLocations.length > 0);
    setNewLocations(syncLocations);

    // If no new locations are available, decline the sync
    if (syncLocations.length <= 0 && deviceFavoritesAccepted === false) {
      declineSyncLocations({ userData, setShowModal });
    }
  }, [deviceFavoritesAccepted, isLoggedIn, userData]);

  // Render modal if showModal is true, user exists, new locations are available and user has accepted the welcome message
  if (
    showModal === false ||
    userData?.user == null ||
    newLocations == null ||
    userData?.user.options.welcomeMessageAccepted === false
  ) {
    return null;
  }

  return (
    <AnimatableModal
      contentLoaded={true}
      onClose={() => {
        setShowModal(false);
      }}
      ariaLabel={translate('login/favoritesSync/modal/ariaLabel')}
      showModal={showModal}
      hideCloseButton={true}
      rounded={true}
      withPadding={true}
    >
      <div className="sync-locations-from-local-storage">
        <div className="sync-locations-from-local-storage__header">
          <Heading size={'4'} level={'h4'}>
            {translate('login/favoritesSync/modal/header')}
          </Heading>
          <Button
            as="button"
            buttonType="bleed"
            iconAfter="icon-cross"
            onClick={() => {
              declineSyncLocations({ userData, setShowModal });
            }}
          ></Button>
        </div>

        <ul className="sync-locations-from-local-storage__location-list">
          {newLocations.map((syncLocation, index) => {
            return (
              <SyncLocationsFromLocalStorage__ListItem
                syncLocation={syncLocation}
                key={`sync-locations-from-local-storage__location-list-item${index}`}
              />
            );
          })}
        </ul>

        <Text size={'4'} as="p" className="sync-locations-from-local-storage__info-text">
          {translate('login/favoritesSync/modal/infoText')}
        </Text>

        <div className="sync-locations-from-local-storage__buttons">
          <Button
            as="button"
            buttonType="default"
            buttonSize="small"
            onClick={() => {
              acceptSyncLocations({ userData, newLocations, setShowModal, syncronizeLocations });
            }}
          >
            {translate('login/favoritesSync/modal/syncButton')}
          </Button>
          <Button
            as="button"
            buttonType="bleed"
            buttonSize="small"
            buttonVariant="neutral"
            onClick={() => {
              declineSyncLocations({ userData, setShowModal });
            }}
          >
            {translate('login/favoritesSync/modal/abortButton')}
          </Button>
        </div>
      </div>
    </AnimatableModal>
  );
}

function storeSyncAcceptedUserId(userId: string) {
  const favoritesLocalStorage = getLocalStorageObject<string[]>('locationSyncAccepted') ?? [];
  getLocalStorageObject<string[]>('locationSyncAccepted');
  setLocalStorageObject('locationSyncAccepted', [...favoritesLocalStorage, userId]);
}

function acceptSyncLocations({
  userData,
  newLocations,
  setShowModal,
  syncronizeLocations
}: {
  userData: IUserData;
  newLocations: ISyncLocation[];
  setShowModal: (show: boolean) => void;
  syncronizeLocations: ({
    locationIdsFavorites,
    locationIdsVisited
  }: {
    locationIdsFavorites: string[];
    locationIdsVisited: string[];
  }) => void;
}) {
  track.event({ category: 'login', action: 'accept_sync_locations' });
  if (userData?.user == null || newLocations == null) {
    setShowModal(false);
    return;
  }
  const favoriteIds: string[] = [];
  const visitedIds: string[] = [];

  newLocations?.forEach(syncLocation => {
    if (syncLocation.type === 'favorite') {
      favoriteIds.push(syncLocation.locationId);
    }
    if (syncLocation.type === 'visited') {
      visitedIds.push(syncLocation.locationId);
    }
  });

  syncronizeLocations({ locationIdsFavorites: favoriteIds, locationIdsVisited: visitedIds });
  setShowModal(false);
  storeSyncAcceptedUserId(userData.user.id);
}

function declineSyncLocations({
  userData,
  setShowModal
}: {
  userData: IUserData;
  setShowModal: (show: boolean) => void;
}) {
  track.event({ category: 'login', action: 'decline_sync_locations' });
  setShowModal(false);
  storeSyncAcceptedUserId(userData.user.id);
}
