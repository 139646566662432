import { COLOR_BACKGROUND_LOWER_DARK, COLOR_BACKGROUND_LOWER_LIGHT } from '@nrk/yr-design-tokens';
import jsCookie from 'js-cookie';
import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import settings from '../../app/settings';
import { reducer } from './helpers/reducer';

interface IProps {
  initialTheme: 'dark' | 'light';
  initialDarkmode: 'auto' | 'on' | 'off';
  children: React.ReactNode;
}

interface IContext {
  initialTheme: 'dark' | 'light';
  theme: 'dark' | 'light';
  initialDarkmode: 'auto' | 'on' | 'off';
  prefersDarkmode: 'auto' | 'on' | 'off';
  setPrefersDarkmode: ({ prefersDarkmode }: { prefersDarkmode: 'on' | 'off' | 'auto' }) => void;
  setTheme: ({ theme }: { theme: 'dark' | 'light' }) => void;
}

const ThemeContext = createContext<IContext | undefined>(undefined);

export function ThemeProvider(props: IProps) {
  const { initialTheme, children, initialDarkmode } = props;

  const [state, dispatch] = useReducer(reducer, { theme: initialTheme, prefersDarkmode: initialDarkmode });

  const setTheme = useCallback(({ theme }: { theme: 'light' | 'dark' }) => {
    // We want to update the meta tag for theme-color when switching between themes.
    const themeColor = document.querySelector('meta[name="theme-color"]');
    const color = theme === 'light' ? COLOR_BACKGROUND_LOWER_LIGHT : COLOR_BACKGROUND_LOWER_DARK;
    if (themeColor) {
      themeColor.setAttribute('content', color);
    }

    document.documentElement.setAttribute('data-theme', theme);

    jsCookie.set(settings.cookies.latestDarkmode, theme, {
      expires: 365,
      path: '/',
      sameSite: 'lax'
    });

    dispatch({ type: 'SET_THEME', theme });
  }, []);

  const setPrefersDarkmode = useCallback(
    ({ prefersDarkmode }: { prefersDarkmode: 'on' | 'off' | 'auto' }) => {
      jsCookie.set(settings.cookies.darkmode, prefersDarkmode, {
        expires: 365,
        path: '/',
        sameSite: 'lax'
      });

      const isDarkmode =
        prefersDarkmode === 'on' ||
        (prefersDarkmode === 'auto' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

      setTheme({ theme: isDarkmode ? 'dark' : 'light' });

      dispatch({ type: 'SET_PREFERS_DARK_MODE', prefersDarkmode });
    },
    [setTheme]
  );

  const value = useMemo(() => {
    const { theme, prefersDarkmode } = state;

    return {
      initialTheme,
      theme,
      prefersDarkmode,
      initialDarkmode,
      setPrefersDarkmode,
      setTheme
    };
  }, [initialTheme, initialDarkmode, setPrefersDarkmode, state, setTheme]);

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}

export function useTheme() {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
}
