import { createContext, useContext } from 'react';

interface IProps {
  nrkAuthFlag: string | undefined;
  children: React.ReactNode;
}

const IsAuthenticatedUserContext = createContext<Boolean>(false);

export function IsAuthenticatedUserProvider(props: IProps) {
  const { nrkAuthFlag, children } = props;

  const value = nrkAuthFlag != null;

  return <IsAuthenticatedUserContext.Provider value={value}>{children}</IsAuthenticatedUserContext.Provider>;
}

export function useIsAuthenticatedUser() {
  const context = useContext(IsAuthenticatedUserContext);

  if (context === undefined) {
    throw new Error('useInitialAuthFlagContext must be used within a InitialAuthFlagContextProvider');
  }

  return context;
}
