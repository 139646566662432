import { useEffect, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { useLogin } from '../../contexts/LoginContext/LoginContext';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Badge } from '../Badge/Badge';
import { LoginModal } from '../LoginModal/LoginModal';
import { Text } from '../Text/Text';
import './LoginPromoBanner.scss';
import { LoginPromoBanner__Button } from './LoginPromoBanner__Button';
import { useIsAuthenticatedUser } from '../../contexts/IsAuthenticatedUserProvider/IsAuthenticatedUserProvider';

export type TLoginPromoBannerVariants = 'login-promo-purple' | 'login-promo-orange' | 'login-promo-blue';

export function LoginPromoBanner() {
  const { isLoggedIn, isLoginClientInitialized } = useLogin();
  const translate = useTranslate();
  const nrkAuthFlag = useIsAuthenticatedUser();

  const [showModal, setShowModal] = useState<Boolean>(false);

  const { isFirstRender } = useAppState();

  const [colorStyle, setColorStyle] = useState<TLoginPromoBannerVariants | undefined>();

  useEffect(() => {
    if (isFirstRender === false) {
      setColorStyle(window.__LOGIN_PROMO_COLOR__);
    }
  }, [isFirstRender]);

  if (nrkAuthFlag === true && isLoginClientInitialized === false) {
    return null;
  }

  if (colorStyle != null && isLoggedIn === false && isLoginClientInitialized === true) {
    return (
      <>
        <div className={`login-promo-banner login-promo-banner--${colorStyle}`} aria-hidden={true}>
          <div className="layout-container login-promo-banner__content">
            <div className="login-promo-banner__text">
              <div className="login-promo-banner__badge-container">
                <Badge type={colorStyle} children={translate('login/promo/notification/badge')} size={'small'}></Badge>
              </div>
              <Text size={'4'}>
                <Text size={'4'} weight={'bold'}>
                  {translate('login/promo/notification/logOn')} &nbsp;
                </Text>
                <Text size={'4'}>{translate('login/promo/notification/infoText')}</Text>
              </Text>
            </div>
            <LoginPromoBanner__Button colorStyle={colorStyle} setShowModal={setShowModal} />
          </div>
        </div>
        {showModal && <LoginModal loginInitiator="promoBanner" onClose={() => setShowModal(false)} />}
      </>
    );
  }

  return null;
}
