import { ClickableLink } from '../Clickable/Clickable';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { Icon } from '@nrk/yr-icons';

export const PageHeader__Logo = (_props: {}) => {
  const localeCode = useLocaleCode();
  const translate = useTranslate();

  const yrLogoLabel = translate('header/home');

  return (
    <ClickableLink
      id="yr-logo"
      aria-label={yrLogoLabel}
      className="page-header__logo"
      href={`/${localeCode}`}
      tabIndex={0}
    >
      {/* TODO(tl): Should it be called icon-logo-yr in yr-icons */}
      {/* TODO(tl): Is it okay to overwrite out icon sizes like this? */}
      <Icon id="logo-yr" className="page-header____logo-yr" size={5} />
    </ClickableLink>
  );
};
