import { PageRedirectError } from '../../app/errors';
import { createPageUrl } from '../../app/urls';
import { IPageDetails } from '../../model/page';

const RE_TEMPLATE = /\{([0-9a-zA-Z]+)\}/g;
const RE_URL = /([^:])(\/{2,})/g;

export function extractDomainName(url: string) {
  let hostname;

  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // find & remove port number
  hostname = hostname.split(':')[0];

  // find & remove "?"
  hostname = hostname.split('?')[0];

  // removes www. from the string if it exists
  hostname = hostname.replace(/^www\./, '');

  return hostname;
}

export function isSameOrigin(url: string) {
  const { location } = window;

  let origin = `${location.protocol}//${location.hostname}`;

  if (location.port) {
    origin += `:${location.port}`;
  }
  return url && url.indexOf(origin) === 0;
}

// The URL path we get from the location API sometimes includes
// leading or trailing space or slash characters.
export function normalizeUrlPath(urlPath: string) {
  let normalizedUrlPath = urlPath;

  // Trim one or more space or slash characters from the start and end of the location path
  normalizedUrlPath = normalizedUrlPath.replace(/^[\s/]+/, '');
  normalizedUrlPath = normalizedUrlPath.replace(/[\s/]+$/, '');

  return normalizedUrlPath;
}

/**
 * Join url segments
 * Handles strings or arrays of strings
 * @returns {String}
 */
export function join(...segments: Array<string | undefined | null | Array<string>>) {
  let leading = '';
  let path: Array<string> = [];

  function add(seg: string | null | undefined) {
    if (seg != null) {
      // Handle leading slash
      if (seg.charAt(0) === '/') {
        // Keep if first segment
        if (!path.length) leading = '/';
        seg = seg.slice(1);
      }
      if (seg.length) path.push(sanitize(seg));
    }
  }

  if (segments.length) {
    segments.forEach(function(segment) {
      // Handle array
      if (Array.isArray(segment)) {
        segment.forEach(add);
      } else {
        add(segment);
      }
    });

    return leading + path.join('/');
  }

  return '';
}

/**
 * Remove trailing '/' from 'url'
 * @param {String} url
 * @returns {String}
 */
export function sanitize(url: string) {
  if (url && url !== '/') {
    url = url.replace(RE_URL, '$1/');
    if (url.charAt(url.length - 1) === '/') url = url.slice(0, -1);
  }

  return url || '';
}

/**
 * Decode 'url'
 */
export function decode(url: string): string {
  if (typeof url === 'string') {
    try {
      url = decodeURI(url);
    } catch (err) {
      url = '';
    }
  }

  return url;
}

/**
 * Encode 'url'
 */
export function encode(url: string): string {
  if (typeof url === 'string') {
    try {
      // Try to decode first in-case url is encoded
      try {
        url = decodeURI(url);
      } catch (err) {
        // Do nothing
      }
      url = encodeURI(url);
    } catch (err) {
      url = '';
    }
  }

  return url;
}

interface ITemplateOptions {
  loose?: boolean;
}

/**
 * Substitute 'data' values in 'str' template
 */
export function template(str: string, data?: any | null, options: ITemplateOptions = {}): string {
  str = String(str).replace(RE_TEMPLATE, function(match, prop) {
    return data && data[prop] != null ? data[prop] : options.loose ? match : '';
  });

  return sanitize(str);
}

export function redirectToExpectedUrlPath({
  pageDetails,
  expectedUrlPath
}: {
  pageDetails: IPageDetails;
  expectedUrlPath: string;
}) {
  const { params, search } = pageDetails;
  const { urlPath } = params;

  // We need to compare the url path from the URL with the expected url path we get from the API.
  // We decode both URL components to be safe, and because the API occasionally includes a leading slash,
  // e.g. for /api/v0/locations/2-6295630?language=nb, we trim any leading slashes before comparing the two.
  const decodedExpectedUrlPath = normalizeUrlPath(decode(expectedUrlPath));
  const decodedUrlPath = urlPath != null ? normalizeUrlPath(decode(urlPath)) : '';

  if (decodedExpectedUrlPath !== decodedUrlPath) {
    const href = createPageUrl({ ...params, urlPath: decodedExpectedUrlPath, search });
    throw new PageRedirectError(href);
  }
}
