import ReactDOM from 'react-dom';
import { useAppState } from '../../app/contexts/AppStateContext';
import { createReturnUrl } from '../../app/urls';
import { useTheme } from '../../contexts/ThemeContext/ThemeContext';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { CloseButton } from '../CloseButton/CloseButton';
import { Heading } from '../Heading/Heading';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import { NRKLoginButton } from '../NRKLoginButtons/NRKLoginButton';
import { Text } from '../Text/Text';
import './EditCustomLocationNamePromo.scss';
import { useTranslate } from '../../lib/hooks/useTranslate';

interface IProps {
  locationId: string;
  handleClose: () => void;
}

export function EditCustomLocationNamePromo(props: IProps) {
  const { locationId, handleClose } = props;
  const localeCode = useLocaleCode();
  const { currentPage } = useAppState();
  const { theme } = useTheme();
  const translate = useTranslate();

  const returnUrl = createReturnUrl({
    localeCode,
    initiator: 'login',
    pageId: currentPage.details.pageId ?? 'home',
    subpageId: currentPage.details.params.subpageId,
    locationId: currentPage.details.params.locationId,
    routeId: currentPage.details.params.routeId,
    editLocationId: locationId
  });

  const modalRoot = document.querySelector('#modalRoot');

  if (modalRoot == null) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalDialog
      onClose={handleClose}
      renderOnServer={false}
      size={'small'}
      hideHeader={true}
      hideBorder={true}
      theme="light-blue"
      ariaLabel={translate('login/editCustomLocationNamePromo/ariaLabel')}
    >
      <div className="edit-custom-location-name-promo__close-button-contianer">
        <CloseButton as="button" buttonSize="medium" className="login-modal__close-button" onClick={handleClose} />
      </div>

      <div>
        <svg role="img" aria-labelledby="desc" xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 960 780">
          <desc></desc>

          <image
            x="0"
            y="0"
            aria-label={translate('login/promo/customName/ariaLabel')}
            href={`/assets/images/custom-name-${localeCode}-${theme}.webp`}
            xlinkHref={`/assets/images/custom-name-${localeCode}-${theme}.webp`}
            height="780"
            width="100%"
          ></image>
        </svg>
      </div>

      <div className="edit-custom-location-name-promo">
        <Heading size={'1'} level={'h1'} className="edit-custom-location-name-promol__header">
          {translate('login/promo/customName/title')}
        </Heading>
        <Text size={'4'} as="p" color="inherit" className="edit-custom-location-name-promo__info-text">
          {translate('login/promo/customName/bodyText')}
        </Text>
        <NRKLoginButton returnUrl={returnUrl} loginInitiator="editLocationNamePromo" />
      </div>
    </ModalDialog>,
    modalRoot
  );
}
