import { createPageUrl } from '../../app/urls';
import { Notification } from '../Notification/Notification';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { IExtremeEventListItem } from '../../model/extreme';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Text } from '../Text/Text';

interface IProps {
  event: IExtremeEventListItem;
}

export function ExtremeWarningsBanner__Item(props: IProps) {
  const { incidentName, eventStatus } = props.event;

  const translate = useTranslate();
  const localeCode = useLocaleCode();

  const href = createPageUrl({ localeCode, pageId: 'extreme', subpageId: incidentName.toLowerCase() });

  const displayedTitle = translate('warning/extreme/title', { incidentName });
  const displayedWarningStatus = translate(`warning/enums/eventStatus/${eventStatus}`).toLowerCase();
  const displayedText = `${displayedTitle} ${displayedWarningStatus}`;

  return (
    <Text
      as="div"
      size="4"
      weight="bold"
      className="extreme-warnings-banner__item"
      data-testid={`extreme-${incidentName}`}
    >
      <Notification text={displayedText} href={href} icon={{ id: 'icon-warning-extreme', type: 'warning' }} />
    </Text>
  );
}
