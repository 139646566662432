import { LocaleCode } from '../model/locale';
import { pages } from './pages';
import { template } from '../lib/helpers/url';
import { IPage } from '../model/page';

interface ICreatePageUrlOptions {
  localeCode: LocaleCode;
  pageId: string;
  subpageId?: string;
  locationId?: string;
  routeId?: string;
  urlPath?: string;
  search?: string;
  hash?: string;
}

export function createPageUrl({
  localeCode,
  pageId,
  subpageId,
  locationId,
  routeId,
  urlPath,
  search,
  hash
}: ICreatePageUrlOptions) {
  let localePageId;
  let localeSubpageId;
  const allPages = [...pages.pages, ...pages.locations, ...pages.pagesWithUrlPath, ...pages.routeForecasts];

  for (const page of allPages) {
    if (page.id === pageId) {
      localePageId = page.path[localeCode];

      if (page.subpages != null) {
        for (const subpage of page.subpages) {
          // If we have a generic subpage path (e.g. extreme page)
          // we use the given subpageId as localeSubpageId
          if (subpage.path[localeCode] === '*') {
            localeSubpageId = subpageId;
            break;
          }

          if (subpage.id === subpageId) {
            localeSubpageId = subpage.path[localeCode];
            break;
          }
        }
      }

      break;
    }
  }

  if (localePageId == null) {
    throw new Error(`Can't find page with pageId ${pageId}`);
  }

  let urlTemplate = '/{localeCode}/{pageId}';

  if (subpageId != null) {
    urlTemplate = `${urlTemplate}/{subpageId}`;

    if (localeSubpageId == null) {
      throw new Error(`Can't find page with subpageId ${subpageId}`);
    }
  }

  if (locationId != null) {
    urlTemplate = `${urlTemplate}/{locationId}`;
  }

  if (routeId != null) {
    urlTemplate = `${urlTemplate}/{routeId}`;
  }

  if (urlPath != null) {
    urlTemplate = `${urlTemplate}/{urlPath}`;
  }

  if (search != null && search !== '') {
    if (search.charAt(0) !== '?') {
      throw new Error('Search string must begin with the character "?"');
    }

    urlTemplate = `${urlTemplate}{search}`;
  }

  if (hash != null && hash !== '') {
    if (hash.charAt(0) !== '#') {
      throw new Error('hash string must begin with the character "#"');
    }

    urlTemplate = `${urlTemplate}{hash}`;
  }

  return template(urlTemplate, {
    localeCode,
    pageId: localePageId,
    subpageId: localeSubpageId,
    locationId,
    routeId,
    urlPath,
    search,
    hash
  });
}

// Construct a URL for the update user page with the given parameters, used to pass to NRK loginClint as a return URL
export function createReturnUrl({
  localeCode,
  initiator,
  pageId,
  subpageId,
  locationId,
  routeId,
  urlPath,
  editLocationId
}: {
  localeCode: LocaleCode;
  initiator: 'login' | 'logout';
  pageId: string;
  subpageId?: string;
  locationId?: string;
  routeId?: string;
  urlPath?: string;
  editLocationId?: string;
}) {
  const updateUserPage = createPageUrl({
    localeCode,
    pageId: 'update-user-page'
  });

  let urlTemplate = `${updateUserPage}?initiator=${initiator}&pageId=${pageId}`;

  if (subpageId != null) {
    urlTemplate = `${urlTemplate}&subpageId=${subpageId}`;
  }

  if (locationId != null) {
    urlTemplate = `${urlTemplate}&locationId=${locationId}`;
  }

  if (routeId != null) {
    urlTemplate = `${urlTemplate}&routeId=${routeId}`;
  }

  if (urlPath != null) {
    urlTemplate = `${urlTemplate}&urlPath=${urlPath}`;
  }

  if (editLocationId != null) {
    urlTemplate = `${urlTemplate}&editLocationId=${editLocationId}`;
  }

  return urlTemplate;
}

export function translatePageUrl({ localeCode, page }: { localeCode: LocaleCode; page: IPage }) {
  const { pageId, subpageId, subpagePath, params, search } = page.details;
  const { locationId, urlPath, routeId } = params;

  return createPageUrl({
    localeCode,
    pageId,
    // When the subpage path is '*' we want to use the subpage id from the params
    // instead so that /en/extreme/Kaia will be correctly translated to
    // /nb/ekstrem/Kaia.
    subpageId: subpagePath === '*' ? params.subpageId : subpageId,
    locationId,
    urlPath,
    routeId,
    // Don't include search when changing language on the statistics page untill we find a way to localize search parameters
    // See: https://nrknyemedier.atlassian.net/browse/YR-3200
    search: pageId !== 'statistics' ? search : undefined
  });
}
