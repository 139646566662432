import CoreDialog from '@nrk/core-dialog/jsx';
import classNames from 'classnames';
import noScroll from 'no-scroll';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { createPageUrl } from '../../app/urls';
import { useLogin } from '../../contexts/LoginContext/LoginContext';
import { track } from '../../lib/analytics/track';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useMapSubpageId } from '../../lib/hooks/useMapSubpageId';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Button } from '../Button/Button';
import { ClickableLink } from '../Clickable/Clickable';
import { CloseButton } from '../CloseButton/CloseButton';
import { LoginModal } from '../LoginModal/LoginModal';
import { ServedBy } from '../ServedBy/ServedBy';
import { Text } from '../Text/Text';
import './Menu.scss';
import { Menu__ThemeSelector } from './Menu_ThemeSelector';
import { Menu__LanguageSelector } from './Menu__LanguageSelector';

interface IProps {
  onToggle: () => void;
}

export const Menu = (props: IProps) => {
  const { onToggle } = props;
  const translate = useTranslate();
  const localeCode = useLocaleCode();
  const mapSubpageId = useMapSubpageId();
  const menuDialogRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const [showModal, setShowModal] = useState<Boolean>(false);

  const { isLoggedIn } = useLogin();

  const userPage = createPageUrl({
    localeCode,
    pageId: 'user-page'
  });

  // We want to toggle no scroll before the browser renders this component for the first time.
  useEffect(() => {
    noScroll.on();

    return () => {
      noScroll.off();
    };
  }, []);

  useEffect(() => {
    if (isVisible === false) {
      setIsVisible(true);
    }
  }, [isVisible]);

  const menuStrings = [
    {
      text: translate('nav/menu/map'),
      url: createPageUrl({
        localeCode,
        pageId: 'standalone-map',
        subpageId: mapSubpageId
      })
    },
    {
      text: translate('nav/menu/weatherWarnings'),
      url: createPageUrl({
        localeCode,
        pageId: 'weather-warnings'
      })
    },
    {
      text: translate('nav/menu/waterTemperatures'),
      url: createPageUrl({
        localeCode,
        pageId: 'water-temperatures-region-list'
      })
    },
    {
      text: translate('nav/menu/snowDepths'),
      url: createPageUrl({
        localeCode,
        pageId: 'snow-depth-region-list'
      })
    },
    {
      text: translate('nav/menu/mountainpasses'),
      url: createPageUrl({
        localeCode,
        pageId: 'mountain-passes',
        subpageId: 'south'
      })
    },
    // The link to BirkebeinerPage is only active a week before and during a Birkebeiner event
    // {
    //   // We have one key for each Birkebeiner event in lokalise: `birkebeinerrittet`, `birkebeinerløpet` and `birkebeinerrennet`
    //   text: translate('birkebeinerrittet'),
    //   url: createPageUrl({
    //     localeCode,
    //     pageId: 'birkebeineren'
    //   })
    // },
    { text: translate('link/help/text'), url: translate('link/help/url') },
    { text: translate('link/contact/text'), url: translate('link/contact/url') }
  ];

  const userButton =
    isLoggedIn === true ? (
      <Button className="menu__user-button" as="link" buttonType="ghost" href={userPage} onClick={onToggle}>
        {translate('login/menu/myProfileButton')}
      </Button>
    ) : (
      <Button
        className="menu__user-button"
        as="button"
        buttonType="default"
        onClick={() => {
          track.event({ category: 'login', action: 'click_from_menu', label: 'menu' });
          setShowModal(true);
        }}
      >
        {translate('login/menu/loginButton')}
      </Button>
    );

  const menuItems = (
    <ul>
      <li key={'user-button'} className="menu__list-item menu__list-item-user-button">
        {userButton}
      </li>
      {menuStrings.map(item => {
        return (
          <li key={item.text} className="menu__list-item">
            <ClickableLink className="menu__link" href={item.url} onClick={onToggle}>
              <Text size="4" weight="bold">
                {item.text}
              </Text>
            </ClickableLink>
          </li>
        );
      })}
      <li className="menu__list-item">
        <Menu__LanguageSelector />
      </li>
      <li className="menu__list-item">
        <Menu__ThemeSelector />
      </li>
    </ul>
  );

  const closeButton = <CloseButton as="button" className="menu__close-button" onClick={onToggle} />;

  const modalRoot = document.querySelector('#modalRoot');
  if (modalRoot == null) {
    return null;
  }

  if (showModal === true) {
    return <LoginModal loginInitiator="menu" onClose={() => setShowModal(false)} />;
  }

  return ReactDOM.createPortal(
    <div className="menu">
      <div
        className={classNames({ menu__dialog: true, 'menu__dialog--visible': isVisible })}
        ref={menuDialogRef}
        data-testid="modal"
      >
        <CoreDialog
          className="menu__core-dialog"
          aria-label={translate('header/menu')}
          onDialogToggle={() => {
            onToggle();
          }}
        >
          <div className="menu__dialog-container">
            {closeButton}
            <div className="menu__dialog-content">
              {menuItems}
              <div className="menu__served-by">
                <ServedBy type="logo" />
              </div>
            </div>
          </div>
        </CoreDialog>
      </div>
    </div>,
    modalRoot
  );
};
