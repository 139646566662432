import classNames from 'classnames';
import { useEffect, useState } from 'react';
import settings from '../../app/settings';
import { requestNextAnimationFrame } from '../../lib/helpers/frame';
import { getLocalStorageObject, isLocalStorageSupported, setLocalStorageObject } from '../../lib/helpers/localStorage';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { LocaleCode } from '../../model/locale';
import { Button } from '../Button/Button';
import { IconButton } from '../IconButton/IconButton';
import { Text } from '../Text/Text';
import './Survey.scss';

export function Survey() {
  const localeCode = useLocaleCode();
  const translate = useTranslate();

  const [showSurvey, setShowSurvey] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [hasAcceptedSurvey, setHasAcceptedSurvey] = useState(false);

  useEffect(() => {
    if (shouldShowSurvey(localeCode)) {
      setShowSurvey(true);
    }
  }, [localeCode]);

  useEffect(() => {
    if (showSurvey === false) {
      return;
    }

    const cancel = requestNextAnimationFrame(() => {
      setIsVisible(true);
    });

    return cancel;
  }, [showSurvey]);

  function handleAccept(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();

    const href = event.currentTarget.getAttribute('href');
    if (href == null) {
      return;
    }

    closeSurvey();

    // Ideally we'd prevent `window.opener` from being accessible to the opened window,
    // but we can't easily do that without causing problems in Safari.
    // See https://mathiasbynens.github.io/rel-noopener/
    window.open(href, '_blank');

    setHasAcceptedSurvey(true);
  }

  function closeSurvey() {
    setShowSurvey(false);

    if (isLocalStorageSupported()) {
      setLocalStorageObject('survey', { version: settings.survey.version });
    }
  }

  // We want to show a second popup for users that has accepted the survey,
  // giving them the opportunity to sign up for other tests in the future
  if (hasAcceptedSurvey === true) {
    return (
      <div className={classNames('survey', { 'survey--is-visible': true })}>
        <div className="survey__box">
          <div className="survey__content">
            <Text as="div" size="5" className="survey__text" color="inherit">
              <p className="survey__title">{translate('survey/accepted/title')}</p>
              <p className="survey__body">{translate('survey/accepted/body')}</p>
            </Text>
            <Button
              className="survey__ok-button"
              as="link"
              href="https://forms.office.com/e/xbB1yx8L3V"
              onClick={() => {
                setHasAcceptedSurvey(false);
              }}
              isExternal={true}
            >
              <Text size="4" weight="bold" color="inherit">
                {translate('survey/accepted/button')}
              </Text>
            </Button>
            <IconButton
              className="survey__close-button"
              as="button"
              buttonType="default"
              buttonShape="radius"
              icon="icon-cross"
              ariaLabel={translate('survey/accepted/cancel')}
              onClick={() => {
                setHasAcceptedSurvey(false);
              }}
            ></IconButton>
          </div>
        </div>
      </div>
    );
  }

  if (showSurvey === false) {
    return null;
  }

  // We want to evenly distribute the survey to point to each url specified in settings.
  const randomizedUrlIndex = Math.floor(Math.random() * settings.survey.url.length);

  return (
    <div className={classNames('survey', { 'survey--is-visible': isVisible })}>
      <div className="survey__box">
        <div className="survey__content">
          <Text as="div" size="5" className="survey__text" color="inherit">
            <p className="survey__title">{translate('survey/title')}</p>
            <p className="survey__body">{translate('survey/body')}</p>
          </Text>
          <Button
            className="survey__ok-button"
            as="link"
            href={settings.survey.url[randomizedUrlIndex]}
            onClick={handleAccept}
            isExternal={true}
          >
            {translate('survey/button')}
          </Button>

          <IconButton
            className="survey__close-button"
            buttonType="default"
            buttonShape="radius"
            as="button"
            icon="icon-cross"
            ariaLabel={translate('survey/cancel')}
            onClick={closeSurvey}
          ></IconButton>
        </div>
      </div>
    </div>
  );
}

interface ISurveyLocalStorageObject {
  version: number;
}

export function shouldShowSurvey(localeCode: LocaleCode) {
  if (settings.site.isBrowserTest) {
    return false;
  }

  if (settings.survey.enabled === false) {
    return false;
  }

  // Only show the survey to users who speak Norwegian
  if (localeCode !== 'nb' && localeCode !== 'nn') {
    return false;
  }

  // If local storage is not supported we don't want to show a survey to the user
  // because they might be unable to close it and save that information
  // back to local storage.
  if (isLocalStorageSupported() === false) {
    return false;
  }

  const localStorageSurvey = getLocalStorageObject<ISurveyLocalStorageObject>('survey');

  // showSurvey is only used for testing / debugging
  // Add `showSurvey = {}` to local storage in your browser to always see the survey that is defined in settings
  const localStorageShowSurvey = getLocalStorageObject<ISurveyLocalStorageObject>('showSurvey');

  if (localStorageShowSurvey != null) {
    return true;
  }

  // We only show the survey to a certain sample rate of our users
  const shouldHaveSurveyBasedOnSampleRate = Math.random() <= settings.survey.sampleRate;

  // If a user is not part of the sample rate that should see the survey, we update local storage to make it seem like this user has already seen the survey
  if (shouldHaveSurveyBasedOnSampleRate === false) {
    setLocalStorageObject('survey', { version: settings.survey.version });
    return false;
  }

  // If we have no existing local storage survey object
  // it means the user has never seen a survey and they should see the current one.
  if (localStorageSurvey == null) {
    return true;
  }

  // If the local storage survey object has an invalid format
  // we ignore it and show the survey.
  if (typeof localStorageSurvey.version !== 'number') {
    return true;
  }

  return localStorageSurvey.version < settings.survey.version;
}
