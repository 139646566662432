import { RedirectLoginClient, UserData } from '@nrk/innlogging-web/redirect-login-client';
import { createContext, useContext, useMemo, useState } from 'react';

interface ILoginState {
  isLoginClientInitialized: boolean;
  isLoggedIn: boolean;
  user?: UserData | undefined;
  userConformsToRequirements?: boolean;
  accessToken?: string;
  loginClient: RedirectLoginClient;
  // showAutoLoginBanner: boolean;
  // showAutoLogoutBanner: boolean;
  // closeAutoLoginBanner: () => void;
  // closeAutoLogoutBanner: () => void;
}

const LoginStateContext = createContext<ILoginState | undefined>(undefined);

interface IProps {
  loginClient: RedirectLoginClient;
  children: React.ReactNode;
}

export function LoginProvider(props: IProps) {
  const { children, loginClient } = props;

  // Initiating OpSession to allow automatically being logged in if you're already logged in
  // loginClient.initOpSession().then(() => {});

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginClientInitialized, setIsLoginClientInitialized] = useState(false);
  const [user, setUser] = useState<UserData | undefined>();

  const [accessToken, setAccessToken] = useState<string | undefined>();
  // const [showAutoLoginBanner, setShowAutoLoginBanner] = useState(false);
  // const [showAutoLogoutBanner, setShowAutoLogoutBanner] = useState(false);

  // const closeAutoLoginBanner = useCallback(() => {
  //   setShowAutoLoginBanner(false);
  // }, []);

  // const closeAutoLogoutBanner = useCallback(() => {
  //   setShowAutoLogoutBanner(false);
  // }, []);

  const value = useMemo(() => {
    return {
      isLoginClientInitialized,
      isLoggedIn,
      // showAutoLoginBanner,
      // showAutoLogoutBanner,
      // closeAutoLoginBanner,
      // closeAutoLogoutBanner,
      user,
      accessToken,
      loginClient
    };
  }, [
    isLoginClientInitialized,
    isLoggedIn,
    // showAutoLoginBanner,
    // showAutoLogoutBanner,
    // closeAutoLoginBanner,
    // closeAutoLogoutBanner,
    user,
    accessToken,
    loginClient
  ]);

  //   loginClient.getSession().then(session => {
  //     setUserConformsToRequirements(session.userConformsToRequirements({ requirements: ['sna'] }));
  //   });

  loginClient.addEventListener('update', event => {
    const { isLoggedIn, user, accessToken, isExpired } = event.detail;

    setIsLoginClientInitialized(true);
    setIsLoggedIn(isLoggedIn);

    if (isExpired === true) {
      setAccessToken(undefined);
    } else {
      setAccessToken(accessToken);
    }

    setUser(user);
  });

  // loginClient.addEventListener('opsession_login', event => {
  //   const { isLoggedIn, user, accessToken } = event.detail;

  //   setIsLoginClientInitialized(true);
  //   setShowAutoLoginBanner(true);
  //   setAccessToken(accessToken);
  //   setIsLoggedIn(isLoggedIn);
  //   setUser(user);
  // });

  // loginClient.addEventListener('opsession_logout', () => {
  //   setIsLoginClientInitialized(true);
  //   setShowAutoLogoutBanner(true);
  //   setIsLoggedIn(false);
  //   setUser(undefined);
  // });

  loginClient.addEventListener('login_error', () => {
    window.location.replace('/innloggingsfeil');
  });

  return <LoginStateContext.Provider value={value}>{children}</LoginStateContext.Provider>;
}

export function useLogin() {
  const context = useContext(LoginStateContext);
  if (__SERVER__) {
    return {
      isLoggedIn: false,
      isLoginClientInitialized: false,
      user: undefined,
      loginClient: undefined,
      accessToken: undefined
      // showAutoLoginBanner: false,
      // showAutoLogoutBanner: false,
      // closeAutoLoginBanner: () => undefined,
      // closeAutoLogoutBanner: () => undefined
    };
  }
  if (context === undefined) {
    throw new Error('useAuth must be used within a LoginProvider');
  }
  return context;
}
