import { useEffect } from 'react';
import { useLogin } from '../../contexts/LoginContext/LoginContext';
import { useFetchUserData } from '../../data/user/hooks';
import { track } from '../../lib/analytics/track';
import { getLocalStorageObject } from '../../lib/helpers/localStorage';
import { EditCustomLocationNameModal } from '../EditCustomLocationNameModal/EditCustomLocationNameModal';
import { EditCustomLocationNamePromo } from '../EditCustomLocationNamePromo/EditCustomLocationNamePromo';

interface IProps {
  locationId: string;
  trackingLabel: 'page-header' | 'location-list';
  handleClose: () => void;
}

export default function EditCustomLocationName(props: IProps) {
  const { locationId, trackingLabel, handleClose } = props;
  const { isLoggedIn, accessToken } = useLogin();
  const { data: userData } = useFetchUserData({ accessToken });
  const deviceFavoritesAccepted =
    getLocalStorageObject<string[]>('locationSyncAccepted')?.includes(userData?.user.id ?? '') ?? false;

  useEffect(() => {
    if (isLoggedIn === true) {
      track.event({ category: 'login', action: 'show_edit_custom_name', label: trackingLabel });
    } else {
      track.event({ category: 'login', action: 'show_custom_name_promo', label: trackingLabel });
    }
  }, [isLoggedIn, trackingLabel]);

  if (
    isLoggedIn === true &&
    (deviceFavoritesAccepted === false || userData?.user.options.welcomeMessageAccepted === false)
  ) {
    return null;
  }

  return (
    <>
      {isLoggedIn === true && (
        <EditCustomLocationNameModal
          locationId={locationId}
          trackingLabel={trackingLabel}
          handleClose={function(): void {
            handleClose();
            localStorage.removeItem('editLocationId');
          }}
        />
      )}
      {isLoggedIn === false && (
        <EditCustomLocationNamePromo
          locationId={locationId}
          handleClose={function(): void {
            handleClose();
          }}
        />
      )}
    </>
  );
}
