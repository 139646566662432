import jsCookie from 'js-cookie';
import { useAppState } from '../../app/contexts/AppStateContext';
import { translatePageUrl } from '../../app/urls';
import settings from '../../app/settings';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { LocaleCode } from '../../model/locale';
import { Button } from '../Button/Button';
import { useYrApiUser } from '../../contexts/YrApiUserContext/YrApiUserContext';
import { useLogin } from '../../contexts/LoginContext/LoginContext';

export function PageFooter__LanguageDropdown() {
  const { currentPage } = useAppState();
  const translate = useTranslate();
  const localeCode = useLocaleCode();
  const { updateUserLanguage } = useYrApiUser();
  const { isLoggedIn } = useLogin();

  const options: JSX.Element[] = [];
  for (const [code, language] of Object.entries(settings.locale.supportedLanguages)) {
    const href = translatePageUrl({ localeCode: code as LocaleCode, page: currentPage });

    options.push(
      <option key={code} lang={language.lang} value={code} data-href={href}>
        {language.title}
      </option>
    );
  }

  const changeLanguage = (newLocaleCode: LocaleCode) => {
    //Set locale code cookie explicitly to prevent loss of choice because of cache
    jsCookie.set(settings.cookies.locale, newLocaleCode, { expires: 365, sameSite: 'lax' });
    if (isLoggedIn === true) {
      updateUserLanguage({ language: newLocaleCode });
    }
  };

  // We have a global event listener in `Body.tsx` which detects
  // when the user changes this dropdown and redirects to the
  // URL from the `data-href` attribute.
  return (
    <Button
      as="select"
      label={translate('nav/menu/chooseLanguage')}
      iconBefore="icon-globe"
      defaultValue={localeCode}
      data-language-dropdown
      onChange={evt => {
        changeLanguage(evt.target.value as LocaleCode);
      }}
    >
      {options}
    </Button>
  );
}
