import { useMutation, useQueryClient } from '@tanstack/react-query';
import settings from '../../app/settings';
import { useAriaLive } from '../../contexts/AriaLiveContext/AriaLiveContext';
import { TAction as TLocationListAction } from '../../contexts/LocationListContext/helpers/reducer';
import { TAction as TYrApiUserAction } from '../../contexts/YrApiUserContext/helpers/reducer';
import { useToast } from '../../contexts/ToastContext/ToastContext';
import { template } from '../../lib/string';
import { IUserData } from '../../model/api/userData';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';

/**
 * @description
 * - All mutations uses a reducer to dispatch the incoming data to the context.
 * - We use these dispatches to update the context state optimistically.
 * - The previous state is saved and used to rollback the state if the mutation fails. by dispatching the previous state onError.
 * - onSettled always run after either onError or onSuccess and is used to invalidate the query to refetch the data.
 * */

export function useAddLocationToLastVisited() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      accessToken,
      locationId
    }: {
      accessToken?: string;
      locationId: string;
      dispatch: (value: TLocationListAction) => void;
    }) => {
      return api<IUserData>({
        path: template(settings.api.paths.userVisited),
        method: 'POST',
        accessToken,
        body: { locationId }
      });
    },
    onMutate: async ({ locationId, accessToken, dispatch }) => {
      dispatch({ type: 'VISITED_LOCATION_ADD', locationId });
      const previousUserData = queryClient.getQueryData(['userData', accessToken]) as IUserData;
      return { previousUserData };
    },
    onError: (_err, { dispatch }, context) => {
      setLocationListContextToPreviousData({ dispatch, previousUserData: context?.previousUserData });
    },
    onSettled: (_data, _error, { accessToken }) => {
      queryClient.invalidateQueries({ queryKey: ['userData', accessToken] });
    }
  });
}

export function useRemoveLocationFromLastVisited() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      accessToken,
      locationId
    }: {
      accessToken?: string;
      locationId: string;
      dispatch: (value: TLocationListAction) => void;
    }) => {
      return api<IUserData>({
        path: template(settings.api.paths.userDeleteVisited, { locationId }),
        method: 'DELETE',
        accessToken
      });
    },
    onMutate: async ({ accessToken, locationId, dispatch }) => {
      dispatch({ type: 'VISITED_LOCATION_REMOVE', locationId });
      const previousUserData = queryClient.getQueryData(['userData', accessToken]) as IUserData;
      return { previousUserData };
    },
    onError: (_err, { dispatch }, context) => {
      setLocationListContextToPreviousData({ dispatch, previousUserData: context?.previousUserData });
    },
    onSettled: (_data, _error, { accessToken }) => {
      queryClient.invalidateQueries({ queryKey: ['userData', accessToken] });
    }
  });
}

export function useSyncronizeLocations() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      accessToken,
      locationIds
    }: {
      accessToken?: string;
      locationIds: { favoriteLocationIds: string[]; visitedLocationIds: string[] };
      dispatch: (value: TLocationListAction) => void;
    }) => {
      try {
        // Try to call both endpoints consecutively
        return await api<IUserData>({
          path: template(settings.api.paths.userFavorites),
          method: 'POST',
          accessToken,
          body: locationIds.favoriteLocationIds
        }).then(async () => {
          await api<IUserData>({
            path: template(settings.api.paths.userSyncronizeVisited),
            method: 'POST',
            accessToken,
            body: locationIds.visitedLocationIds
          });
        });
      } catch (_err) {
        // Retry syncronizing visited in case it never got called due to favorites call failing
        return await api<IUserData>({
          path: template(settings.api.paths.userSyncronizeVisited),
          method: 'POST',
          accessToken,
          body: locationIds.visitedLocationIds
        });
      }
    },
    onMutate: async ({ accessToken, locationIds, dispatch }) => {
      const previousUserData = queryClient.getQueryData(['userData', accessToken]) as IUserData;
      const updatedUserData = { ...previousUserData };

      updatedUserData.user.locations.favorites = [
        ...previousUserData.user.locations.favorites,
        ...locationIds.favoriteLocationIds
      ];

      updatedUserData.user.locations.visited = [
        ...previousUserData.user.locations.visited,
        ...locationIds.visitedLocationIds
      ];
      dispatch({
        type: 'UPDATE_USERDATA',
        favouritedLocationIds: updatedUserData.user.locations.favorites,
        visitedLocationIds: updatedUserData.user.locations.visited,
        prepopulatedLocationIds: [],
        customLocationNames: []
      });
      return { previousUserData };
    },
    onError: (_err, { dispatch }, context) => {
      setLocationListContextToPreviousData({ dispatch, previousUserData: context?.previousUserData });
    },
    onSettled: (_data, _error, { accessToken }) => {
      queryClient.invalidateQueries({ queryKey: ['userData', accessToken] });
    }
  });
}

export function useAddLocationsToFavorites() {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const { addAriaLive } = useAriaLive();

  return useMutation({
    mutationFn: ({
      accessToken,
      locationIds
    }: {
      accessToken?: string;
      locationIds: string[];
      dispatch: (vallue: TLocationListAction) => void;
      toastText?: string;
    }) => {
      return api<IUserData>({
        path: template(settings.api.paths.userFavorites),
        method: 'POST',
        accessToken,
        body: locationIds
      });
    },
    onMutate: async ({ accessToken, locationIds, dispatch }) => {
      const previousUserData = queryClient.getQueryData(['userData', accessToken]) as IUserData;
      dispatch({ type: 'FAVOURITED_LOCATION_ADD', locationId: locationIds[0] });
      return { previousUserData };
    },
    onError: (_err, { dispatch }, context) => {
      setLocationListContextToPreviousData({ dispatch, previousUserData: context?.previousUserData });
    },
    onSettled: (_data, _error, { accessToken }) => {
      queryClient.invalidateQueries({ queryKey: ['userData', accessToken] });
    },
    onSuccess(_data, { toastText }) {
      //Display toast on success:
      if (toastText != null) {
        addToast({
          type: 'NOTIFICATION',
          content: { iconId: 'icon-star-filled', text: toastText }
        });

        addAriaLive({ text: toastText });
      }
    }
  });
}

export function useRemoveLocationFromFavourites() {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const { addAriaLive } = useAriaLive();

  return useMutation({
    mutationFn: ({
      accessToken,
      locationId
    }: {
      accessToken?: string;
      locationId: string;
      dispatch: (value: TLocationListAction) => void;
      toastText: string;
    }) => {
      return api<IUserData>({
        path: template(settings.api.paths.userDeleteFavorite, { locationId }),
        method: 'DELETE',
        accessToken
      });
    },
    onMutate: async ({ accessToken, locationId, dispatch }) => {
      const previousUserData = queryClient.getQueryData(['userData', accessToken]) as IUserData;
      dispatch({ type: 'FAVOURITED_LOCATION_REMOVE', locationId });
      return { previousUserData };
    },
    onError: (_err, { dispatch }, context) => {
      setLocationListContextToPreviousData({ dispatch, previousUserData: context?.previousUserData });
    },
    onSettled: (_data, _error, { accessToken }) => {
      queryClient.invalidateQueries({ queryKey: ['userData', accessToken] });
    },
    onSuccess(_data, { toastText }) {
      //Display toast on success:
      addToast({
        type: 'NOTIFICATION',
        content: { iconId: 'icon-star', text: toastText }
      });

      addAriaLive({ text: toastText });
    }
  });
}

export function useSetCustomLocationName() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      accessToken,
      locationId,
      name
    }: {
      accessToken: string;
      locationId: string;
      name: string;
      dispatch: (value: TLocationListAction) => void;
    }) => {
      return api<IUserData>({
        path: template(settings.api.paths.userCustomLocationName),
        method: 'POST',
        accessToken,
        body: { locationId, name: name }
      });
    },
    onMutate: async ({ accessToken, locationId, name, dispatch }) => {
      const previousUserData = queryClient.getQueryData(['userData', accessToken]) as IUserData;
      dispatch({ type: 'SET_CUSTOM_LOCATION_NAME', locationId, name });
      return { previousUserData };
    },
    onError: (_err, { dispatch }, context) => {
      setLocationListContextToPreviousData({ dispatch, previousUserData: context?.previousUserData });
    },
    onSettled: (_data, _error, { accessToken }) => {
      queryClient.invalidateQueries({ queryKey: ['userData', accessToken] });
    }
  });
}

export function useDeleteCustomLocationName() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      accessToken,
      locationId
    }: {
      accessToken: string;
      locationId: string;
      dispatch: (value: TLocationListAction) => void;
    }) => {
      return api<IUserData>({
        path: template(settings.api.paths.userDeleteCustomLocationName, { locationId }),
        method: 'DELETE',
        accessToken
      });
    },
    onMutate: async ({ accessToken, locationId, dispatch }) => {
      const previousUserData = queryClient.getQueryData(['userData', accessToken]) as IUserData;
      dispatch({ type: 'DELETE_CUSTOM_LOCATION_NAME', locationId });
      return { previousUserData };
    },
    onError: (_err, { dispatch }, context) => {
      setLocationListContextToPreviousData({ dispatch, previousUserData: context?.previousUserData });
    },
    onSettled: (_data, _error, { accessToken }) => {
      queryClient.invalidateQueries({ queryKey: ['userData', accessToken] });
    }
  });
}

export function useReplaceLocationFavorites() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      accessToken,
      locationIds
    }: {
      accessToken?: string;
      locationIds: string[];
      dispatch: (value: TLocationListAction) => void;
    }) => {
      return api<IUserData>({
        path: template(settings.api.paths.userFavorites),
        method: 'PUT',
        accessToken,
        body: locationIds
      });
    },
    onMutate: async ({ accessToken, locationIds, dispatch }) => {
      const previousUserData = queryClient.getQueryData(['userData', accessToken]) as IUserData;
      dispatch({
        type: 'UPDATE_FAVORITE_LOCATIONS',
        favouritedLocationIds: locationIds
      });
      return { previousUserData };
    },
    onError: (_err, { dispatch }, context) => {
      setLocationListContextToPreviousData({ dispatch, previousUserData: context?.previousUserData });
    },
    onSettled: (_data, _error, { accessToken }) => {
      queryClient.invalidateQueries({ queryKey: ['userData', accessToken] });
    }
  });
}

export function useUpdateLanguage() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      accessToken,
      language
    }: {
      accessToken?: string;
      language: LocaleCode;
      dispatch: (value: TYrApiUserAction) => void;
    }) => {
      const previousUserData = queryClient.getQueryData(['userData', accessToken]) as IUserData;

      const updatedUserData = { ...previousUserData };
      updatedUserData.user.options.language = language;

      return api<IUserData>({
        path: template(settings.api.paths.userOptions),
        method: 'POST',
        accessToken,
        body: updatedUserData.user.options
      });
    },
    onMutate: async ({ accessToken, language, dispatch }) => {
      const previousUserData = queryClient.getQueryData(['userData', accessToken]) as IUserData;
      dispatch({ type: 'UPDATE_LANGUAGE', language });
      return { previousUserData };
    },
    onError: (_err, { dispatch }, context) => {
      if (context?.previousUserData.user.options.language != null) {
        dispatch({ type: 'UPDATE_LANGUAGE', language: context?.previousUserData.user.options.language });
      }
    },
    onSettled: (_data, _error, { accessToken }) => {
      queryClient.invalidateQueries({ queryKey: ['userData', accessToken] });
    }
  });
}

export function useUpdateWelcomeMessageAccepted() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      accessToken,
      accepted
    }: {
      accessToken?: string;
      accepted: boolean;
      dispatch: (value: TYrApiUserAction) => void;
    }) => {
      const previousUserData = queryClient.getQueryData(['userData', accessToken]) as IUserData;

      const updatedUserData = { ...previousUserData };
      updatedUserData.user.options.welcomeMessageAccepted = accepted;

      return api<IUserData>({
        path: template(settings.api.paths.userOptions),
        method: 'POST',
        accessToken,
        body: updatedUserData.user.options
      });
    },
    onMutate: async ({ accessToken, accepted, dispatch }) => {
      const previousUserData = queryClient.getQueryData(['userData', accessToken]) as IUserData;
      dispatch({ type: 'UPDATE_WELCOME_MESSAGE_ACCEPTED', welcomeMessageAccepted: accepted });
      return { previousUserData };
    },
    onError: (_err, { dispatch }, context) => {
      if (context?.previousUserData.user.options.welcomeMessageAccepted) {
        dispatch({
          type: 'UPDATE_WELCOME_MESSAGE_ACCEPTED',
          welcomeMessageAccepted: context?.previousUserData.user.options.welcomeMessageAccepted
        });
      }
    },
    onSettled: (_data, _error, { accessToken }) => {
      queryClient.invalidateQueries({ queryKey: ['userData', accessToken] });
    }
  });
}

function setLocationListContextToPreviousData({
  dispatch,
  previousUserData
}: {
  dispatch: (value: TLocationListAction) => void;
  previousUserData?: IUserData;
}) {
  if (previousUserData == null) {
    return;
  }
  dispatch({
    type: 'UPDATE_USERDATA',
    favouritedLocationIds: previousUserData.user.locations.favorites,
    visitedLocationIds: previousUserData.user.locations.visited,
    prepopulatedLocationIds: [],
    customLocationNames: previousUserData.user.locations.customNames
  });
}
