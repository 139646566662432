import classNames from 'classnames';
import { TAirQualitySeverityType } from '../AirQualityCard/AirQualityCard__Index';
import { TAuroraForecastConditionType } from '../AuroraPolarisCard/AuroraPolarisCard__Hover';
import { TLoginPromoBannerVariants } from '../LoginPromoBanner/LoginPromoBanner';
import { TPollenSeverityType } from '../PollenCard/PollenCard__DaySummary';
import { Text } from '../Text/Text';
import { TTwentyOneDayForecastFrostType } from '../TwentyOndeDayForecastSummaryCard/TwentyOndeDayForecastSummaryCard';
import { TUvSeverityType } from '../UvCard/UvCard__Index';
import './Badge.scss';

export interface IProps {
  className?: string;
  type:
    | 'new'
    | 'precipitation'
    | 'temperature-warm'
    | 'temperature-cold'
    | 'wind'
    | 'snow-depth'
    | 'cloud-cover'
    | TLoginPromoBannerVariants
    | TPollenSeverityType
    | TAirQualitySeverityType
    | TUvSeverityType
    | TAuroraForecastConditionType
    | TTwentyOneDayForecastFrostType;
  children: React.ReactNode;
  /**
   * Optional size param, defaults to small
   */
  size?: 'small' | 'default';
}

export function Badge(props: IProps) {
  const { className, type, children, size = 'default' } = props;

  const badgeClassNames = classNames('badge', `badge--${type}`, `badge--${size}`, className);

  const textSize = size === 'small' ? '5' : '4';
  const textWeight = size === 'small' ? 'normal' : 'bold';

  return (
    <div className={badgeClassNames}>
      <Text size={textSize} weight={textWeight} color={'inherit'}>
        {children}
      </Text>
    </div>
  );
}
