import { useAppState } from '../../app/contexts/AppStateContext';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Button } from '../Button/Button';
import { ClickableLink } from '../Clickable/Clickable';
import { Heading } from '../Heading/Heading';
import { LogoMet } from '../LogoMet/LogoMet';
import { LogoNrk } from '../LogoNrk/LogoNrk';
import './PageFooter.scss';
import { PageFooter__LanguageDropdown } from './PageFooter__LanguageDropdown';
import { PageFooter__Link } from './PageFooter__Link';

export function PageFooter() {
  const { currentPageSettings } = useAppState();
  const translate = useTranslate();

  const { isModal } = currentPageSettings;

  return (
    <footer id="footer" className="page-footer" aria-hidden={isModal} role="contentinfo">
      <Heading level="h2" size="2" hidden={true}>
        {translate('footer/header')}
      </Heading>

      <div className="layout-container layout-container--with-padding">
        <div className="page-footer__grid">
          <span className="page-footer__served-by">{translate('global/servedBy/header')}</span>

          <div className="page-footer__logos">
            <ClickableLink
              className="page-footer__logo-link page-footer__logo-link--nrk"
              href="http://www.nrk.no"
              data-app-tracking-source="footer"
              isExternal={true}
            >
              <LogoNrk className="page-footer__logo" />
            </ClickableLink>

            <ClickableLink
              className="page-footer__logo-link page-footer__logo-link--met"
              href="http://www.met.no"
              data-app-tracking-source="footer"
              isExternal={true}
            >
              <LogoMet className="page-footer__logo" />
            </ClickableLink>
          </div>

          <div className="page-footer__text">
            <span className="page-footer__copyright-text">
              {translate('footer/copyrightText', { currentYear: getCurrentYear() })}
            </span>
            <div className="page-footer__links-list-divider" color="inherit"></div>
            <span>{translate('footer/editorLabels/yr')}: Ingrid Støver Jensen</span>
            <div className="page-footer__links-list-divider" color="inherit"></div>
            <span>{translate('footer/editorLabels/nrk')}: Vibeke Fürst Haugen</span>
            <div className="page-footer__links-list-divider" color="inherit"></div>
            <span>{translate('footer/editorLabels/met')}: Roar Skålin</span>
          </div>

          <div className="page-footer__toolbar">
            <Button
              as="link"
              iconBefore="icon-help"
              isExternal={true}
              href={translate('link/seeAllHelpArticles/url')}
              data-app-tracking-source="footer"
            >
              {translate('link/seeAllHelpArticles/text')}
            </Button>

            <Button
              as="link"
              iconBefore="icon-edit"
              isExternal={true}
              href={translate('link/contact/url')}
              data-app-tracking-source="footer"
            >
              {translate('link/contact/text')}
            </Button>

            <PageFooter__LanguageDropdown />
          </div>

          <div className="page-footer__links">
            <ul className="page-footer__links-list">
              <li>
                <PageFooter__Link href={translate('link/privacy/url')}>
                  {translate('link/privacy/text')}
                </PageFooter__Link>
              </li>
              <li>
                <PageFooter__Link href={translate('link/cookies/url')}>
                  {translate('link/cookies/text')}
                </PageFooter__Link>
              </li>
              <li>
                <PageFooter__Link href={translate('link/collaborators/url')}>
                  {translate('link/collaborators/text')}
                </PageFooter__Link>
              </li>
            </ul>

            <div className="page-footer__links-list-divider" color="inherit"></div>

            <ul className="page-footer__links-list">
              <li>
                <PageFooter__Link href={translate('link/ios/url')}>{translate('link/ios/text')}</PageFooter__Link>
              </li>
              <li>
                <PageFooter__Link href={translate('link/android/url')}>
                  {translate('link/android/text')}
                </PageFooter__Link>
              </li>
              <li>
                <PageFooter__Link href={translate('link/yrForDevelopers/url')}>
                  {translate('link/yrForDevelopers/text')}
                </PageFooter__Link>
              </li>
              <li>
                <PageFooter__Link href={translate('link/waterTemperatures/url')}>
                  {translate('link/waterTemperatures/text')}
                </PageFooter__Link>
              </li>
            </ul>

            <div className="page-footer__links-list-divider" color="inherit"></div>

            <ul className="page-footer__links-list">
              <li>
                <PageFooter__Link href={translate('link/facebook/url')}>
                  {translate('link/facebook/text')}
                </PageFooter__Link>
              </li>
              <li>
                <PageFooter__Link href={translate('link/twitter/url')}>
                  {translate('link/twitter/text')}
                </PageFooter__Link>
              </li>
              <li>
                <PageFooter__Link href={translate('link/instagram/url')}>
                  {translate('link/instagram/text')}
                </PageFooter__Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

function getCurrentYear() {
  return new Date().getFullYear();
}
