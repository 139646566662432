export const ESCAPE = 27;
export const LEFT = 37;
export const RIGHT = 39;
export const ENTER = 13;
const TAB = 9;

/**
 * Check if the event has any active modifiers such as ctrl or shift etc.
 * @param {KeyboardEvent} event
 * @returns {Boolean} True if the event has any active modifiers
 */
export function eventHasActiveModifiers(event: KeyboardEvent) {
  return event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;
}

export function isTab(keyEvent: KeyboardEvent) {
  if (keyEvent.key !== undefined) {
    return keyEvent.key === 'Tab';
  } else if (keyEvent.keyCode !== undefined) {
    return keyEvent.keyCode === TAB;
  }

  return false;
}
