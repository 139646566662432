import { useLogin } from '../../contexts/LoginContext/LoginContext';
import { track } from '../../lib/analytics/track';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Button } from '../Button/Button';
import './NRKLoginButton.scss';

export type TLoginInitiator = 'promoBanner' | 'menu' | 'editLocationNamePromo';

const TRACKING_LABELS: {
  [key in TLoginInitiator]: string;
} = {
  promoBanner: 'promo-banner',
  menu: 'menu',
  editLocationNamePromo: 'edit-location-name-promo'
};

// See context here: https://nrkconfluence.atlassian.net/wiki/spaces/PER/pages/1211793514/Oversikt+over+context
const NRK_CONTEXTS: {
  [key in TLoginInitiator]: string;
} = {
  promoBanner: 'yrLoginAnnouncementBanner',
  menu: 'yrLoginButtonMainMenu',
  editLocationNamePromo: 'yrLoginEditNameGate'
};

interface IProps {
  returnUrl: string;
  loginInitiator: TLoginInitiator;
}

export function NRKLoginButton(props: IProps) {
  const { returnUrl, loginInitiator } = props;
  const { loginClient } = useLogin();
  const translate = useTranslate();

  const likelyYrOnlyUser = window.__LIKELY_YR_ONLY_USER__;

  return (
    <>
      <Button
        as="button"
        buttonType="ghost"
        buttonVariant="accent"
        onClick={() => {
          track.event({
            category: 'login',
            action: 'click_logon_button',
            label: TRACKING_LABELS[loginInitiator]
          });
          if (loginClient != null) {
            loginClient.login({
              loginLandingPage: 'LoginUser',
              returnUrl,
              likelyYrOnlyUser,
              nrkCtx: NRK_CONTEXTS[loginInitiator]
            });
          }
        }}
      >
        {translate('login/loginButton')}
      </Button>
    </>
  );
}
