import { LoadableComponent } from '@loadable/component';
import createDebug from 'debug';
import { History, Location } from 'history';
import { useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useQueryClient } from '@tanstack/react-query';
import { AppStateProvider } from '../../app/contexts/AppStateContext';
import { PageRedirectError } from '../../app/errors';
import { updateMeta } from '../../app/meta';
import { fetchPageData, getPageDetails } from '../../app/routes';
import { GeolocationProvider } from '../../contexts/GeolocationContext/GeolocationContext';
import { useTheme } from '../../contexts/ThemeContext/ThemeContext';
import { track } from '../../lib/analytics/track';
import { findAncestorLink } from '../../lib/element';
import { focusPage } from '../../lib/helpers/accessibility';
import { createTranslateFunction } from '../../lib/helpers/translations';
import { isSameOrigin } from '../../lib/helpers/url';
import { IPage, isSamePage } from '../../model/page';
import { App } from '../App/App';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import './Root.scss';

const debug = createDebug('yr:root');

interface IProps {
  history: History<any>;
}

export const Root = (props: IProps) => {
  const { history } = props;

  const queryClient = useQueryClient();

  const { setPrefersDarkmode, setTheme, prefersDarkmode } = useTheme();

  const initialPage = useMemo(() => getPage(history.location), [history.location]);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [currentPage, setCurrentPage] = useState<IPage>(initialPage);
  const [currentPageSettings, setCurrentPageSettings] = useState(
    initialPage.details.handler.getSettings({ pageDetails: initialPage.details })
  );
  const [previousPage, setPreviousPage] = useState<IPage>();
  const [lastTrackedPage, setLastTrackedPage] = useState<IPage>();
  const [isFetchingCurrentPage, setIsFetchingCurrentPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const translate = useMemo(() => {
    const { details } = currentPage;

    return createTranslateFunction(details.params.localeCode);
  }, [currentPage]);

  useEffect(() => {
    // Set `isFirstRender` to false immediately after the first render.
    // This variable is always true on the server and true during the first
    // render in the browser.
    // This variable can be used by components to ensure they render the same
    // thing on the server and during first render on the client.
    // A component might, for example, want to render a button as a link in the
    // markup rendered by the server for accessibility reasons for users using
    // older browser that can't run our React application, but render it as a
    // button when the React application has started.
    // React will warn about server and browser markup differences, and fixing
    // those warnings is important because React does not guarantee that
    // differences will be patched up during hydration in case of mismatches.
    // See https://reactjs.org/docs/react-dom.html#hydrate
    setIsFirstRender(false);
  }, []);

  // Set correct theme
  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    const cookieName = 'yr-darkmode';
    const cookies = decodeURIComponent(document.cookie).split(';');

    const darkmodeCookie = cookies.filter(s => s.indexOf(cookieName) !== -1)[0] ?? null;

    // Set prefersDarkmode off if a user has no cookies.
    let prefersDarkmode: 'on' | 'off' | 'auto' = 'off';
    if (darkmodeCookie) {
      prefersDarkmode = darkmodeCookie.split('yr-darkmode=')[1] as 'on' | 'off' | 'auto';
    }

    setPrefersDarkmode({ prefersDarkmode });
  }, [isFirstRender, setPrefersDarkmode]);

  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    if ('matchMedia' in window === false) {
      return;
    }

    function activateDarkMode(e: MediaQueryListEvent) {
      const theme = e.matches ? 'dark' : 'light';
      setTheme({ theme });
    }

    const cookieName = 'yr-darkmode';
    const cookies = decodeURIComponent(document.cookie).split(';');

    const darkmodeCookie = cookies.filter(s => s.indexOf(cookieName) !== -1)[0] ?? null;

    // Set prefersDarkmode off if a user has no cookies.
    let userPrefersDarkmode: 'on' | 'off' | 'auto' = 'off';
    if (darkmodeCookie) {
      userPrefersDarkmode = darkmodeCookie.split('yr-darkmode=')[1] as 'on' | 'off' | 'auto';
    }

    const darkModePreference = window.matchMedia('(prefers-color-scheme: dark)');
    if (userPrefersDarkmode === 'auto') {
      darkModePreference.addEventListener('change', activateDarkMode);
    }

    return () => {
      darkModePreference.removeEventListener('change', activateDarkMode);
    };
  }, [isFirstRender, setTheme, prefersDarkmode]);

  // Listen for page navigation and load the new page's component before changing to it
  useEffect(() => {
    let abortController: AbortController;

    const unlisten = history.listen((newLocation, action) => {
      const newPage = getPage(newLocation);
      const newPageSettings = newPage.details.handler.getSettings({ pageDetails: newPage.details });

      // Abort any previous page component we may be fetching.
      // This prevents us from accidentally changing to the wrong page if the
      // user navigates quickly between pages and the component for an old page
      // the user has already navigated away from finish loading a few seconds
      // later.
      if (abortController) {
        abortController.abort();
      }

      abortController = new AbortController();

      debug("loading newPage's component due to history callback");
      loadComponent(newPage.details.component, abortController.signal)
        .then(() => {
          // We use `unstable_batchedUpdates()` here because we don't want to trigger
          // a render until after this function has finished. This function gets
          // called in a callback outside of a React event handler which means the
          // set state calls do not get batched, and each set state call triggers a
          // new render.
          // See https://github.com/facebook/react/issues/14259#issuecomment-439632622
          // Using `unstable_batchedUpdates()` is relatively safe for now, see
          // https://github.com/facebook/react/issues/14259#issuecomment-439702367
          // and
          // https://github.com/facebook/react/issues/14259#issuecomment-505918440
          // if `unstable_batchedUpdates()` gets removed in a future version of React
          // we will have to revisit this. Using an intermediary `nextPage` variable
          // and a `useEffect()` would be a safe solution, but that can cause things
          // to render out of sync, e.g. `@nrk/core-tabs`
          unstable_batchedUpdates(() => {
            // We should not set "previousPage" if the history was updated as a result of a
            // `REPLACE` action since this action signifies an intent to replace the current page
            // without adding a history entry. This action can occur as a result of calling
            // `history.replace()` as opposed to `history.push()`.
            if (action !== 'REPLACE') {
              debug('setting "previousPage" due to history callback"');
              setPreviousPage(currentPage);
            }

            debug('setting "currentPage" due to history callback"');
            setCurrentPage(newPage);

            debug('setting "currentPageSettings" due to history callback"');
            setCurrentPageSettings(newPageSettings);

            // Toggle class names as a result of changing page
            togglePageIdClassNames({
              previousPageId: currentPage.details.pageId,
              currentPageId: newPage.details.pageId
            });

            debug('setting "isFetching" to "true" due to history callback"');
            setIsFetchingCurrentPage(true);

            const currentPageParams = {
              pageId: currentPage.details.params.pageId,
              locationId: currentPage.details.params.locationId,
              routeId: currentPage.details.params.routeId
            };

            const newPageParams = {
              pageId: newPage.details.params.pageId,
              locationId: newPage.details.params.locationId,
              routeId: newPage.details.params.routeId
            };

            // Only set is loading if page root or location id changes.
            // We don't want to show the loading fade in animation when changing subpages.
            if (isSamePage(currentPageParams, newPageParams) === false) {
              debug('setting "isLoading" to "true" due to history callback"');
              setIsLoading(true);
            }
          });
        })
        .catch(() => {
          // If the component failed to load we reload the current URL,
          // which is the URL the user tried to navigate to and not the
          // previous URL. This means the user will at least get the
          // server rendered version of the page instead of just an error
          // message. The component could fail to load due to a temporary
          // network error, or because the user's browser loaded an older
          // cached version of yr.no and the browser is trying to fetch a
          // component's hashed JavaScript bundle filename which no longer
          // exists. Reloading should hopefully give these users new markup
          // with references to the current hashed JavaScript bundle filenames.
          window.location.reload();
        });
    });

    return unlisten;
  }, [currentPage, history]);

  // Scroll to the top when the user navigates to a new page
  useEffect(() => {
    if (previousPage == null) {
      return;
    }

    if (isSamePage(previousPage.details.params, currentPage.details.params) === true) {
      return;
    }

    window.scrollTo(0, 0);
  }, [previousPage, currentPage]);

  // Set focus when navigating to a new page to aid users with screen readers.
  // We focus the location heading on location pages and the app root itself on other pages.
  useEffect(() => {
    if (previousPage == null) {
      return;
    }

    const previousPageParams = {
      pageId: previousPage.details.params.pageId,
      locationId: previousPage.details.params.locationId,
      routeId: previousPage.details.params.routeId
    };

    const currentPageParams = {
      pageId: currentPage.details.params.pageId,
      locationId: currentPage.details.params.locationId,
      routeId: previousPage.details.params.routeId
    };

    if (isSamePage(previousPageParams, currentPageParams) === true) {
      return;
    }

    focusPage();
  }, [currentPage, previousPage]);

  // Fetch data for the new page when the current page changes
  useEffect(() => {
    // Set `isFetching` to true whenever we fetch page data.
    // A new fetch can happen when the old data fetched for the current page
    // expires, not just when we change to a new page.
    function handlePageFetchStart() {
      debug('setting "isFetching" to "true" due to the "handlePageFetchStart()" callback');
      setIsFetchingCurrentPage(true);
    }

    function handlePageFetchDone() {
      debug('setting "isFetching" and "isLoading" to "false" due to the "handlePageFetchDone()" callback');
      setIsFetchingCurrentPage(false);
      setIsLoading(false);
    }

    function handlePageFetchError(error: Error) {
      // We can get a PageRedirectError if the location path in the URL doesn't
      // match the response from the API. If that is the case we want to simply
      // change the current URL to the correct URL.
      if (error instanceof PageRedirectError) {
        history.replace(error.url);
        return;
      }

      debug('setting "isFetching" and "isLoading" to "false" due to the "handlePageFetchError()" callback');
      setIsFetchingCurrentPage(false);
      setIsLoading(false);

      // Throw the error so our global error handler can handle the error
      throw error;
    }

    fetchPageData({
      pathname: currentPage.location.pathname,
      search: currentPage.location.search,
      queryClient,
      onFetchStart: handlePageFetchStart,
      onFetchDone: handlePageFetchDone,
      onFetchError: handlePageFetchError
    });
  }, [currentPage, history, queryClient]);

  // Update meta data and track navigation to a new page
  useEffect(() => {
    // If the last page we tracked is the current page we don't want to track it again
    if (lastTrackedPage === currentPage) {
      return;
    }

    // We need to wait for fetching to finish before we can update meta data
    // since we occasionally use fetched data in the title and description.
    if (isFetchingCurrentPage) {
      return;
    }

    const { details } = currentPage;

    const url = `${currentPage.location.pathname}${currentPage.location.search}`;
    const title = details.name;

    // We need to update meta data before tracking because core-analytics
    // sends in og:url, og:title, etc.
    debug('updating meta data because "currentPage" changed');
    updateMeta(currentPage.location.pathname, currentPage.location.search, queryClient, translate);

    debug('tracking page change because "currentPage" changed');
    track.page(title, url);

    setLastTrackedPage(currentPage);
  }, [currentPage, isFetchingCurrentPage, lastTrackedPage, translate, queryClient]);

  // Mark location as visited when navigating to a new page if the page has a location id.
  useEffect(() => {
    // Don't set visited location while we're still in the first render phase
    if (isFirstRender === true) {
      return;
    }

    const locationId = currentPage.details.params.locationId;
    if (locationId == null) {
      return;
    }
  }, [isFirstRender, currentPage, queryClient]);

  // Toggle the class `mode-loading` on the `html` element whenever we start or
  // stop fetching page data. This results in a visible blue progress animation
  // at the top of page.
  useEffect(() => {
    const html = document.documentElement;

    if (isFetchingCurrentPage) {
      debug('adding class name "mode-loading" to "<html>" because "isFetching" is true');
      html.classList.add('mode-loading');
    } else {
      debug('removing class name "mode-loading" from "<html>" because "isFetching" is false');
      html.classList.remove('mode-loading');
    }
  }, [isFetchingCurrentPage]);

  // Handle clicks on links and navigate to the link's `href` using
  // history.push(). This means we can use regular links instead of having to
  // use React Router's `<Link>` component.
  function handleRootClick(event: React.MouseEvent) {
    const { target } = event;

    if (!(target instanceof HTMLElement)) {
      return;
    }

    // Don't do anything if someone has called `event.preventDefault()` on this event.
    if (event.defaultPrevented === true) {
      return;
    }

    // We only handle link clicks using the left mouse button.
    // The middle mouse button is used to open links in a new tab and the right
    // mouse button is used to open the right click menu on links. The only
    // button we can safely assume should be used to open the link normally is
    // the left mouse button. We need to let the browser handle the event in
    // order to get the default behaviour for this event if the user did not
    // click the link using the left mouse button.
    if (event.button !== 0) {
      return;
    }

    // Check if any modifiers are present. Modifiers most likely mean the user
    // wants to let the browser open the link in a new tab or a new window. We
    // need to let the browser handle the event in order to get the default
    // behaviour for this event.
    if (event.metaKey || event.ctrlKey || event.shiftKey) {
      return;
    }

    const linkElement = findAncestorLink(target);
    if (linkElement == null) {
      return;
    }

    const decodedHash = decodeURIComponent(linkElement.hash);

    // Links that point to an id should be handled by the browser
    const linkElementHref = linkElement.getAttribute('href');
    if ((linkElementHref && linkElementHref.charAt(0) === '#') || decodedHash.charAt(0) === '#') {
      return;
    }

    const decodedHref = decodeURIComponent(linkElement.href);
    const decodedPathname = decodeURIComponent(linkElement.pathname);
    const decodedSearch = decodeURIComponent(linkElement.search);

    // Handle links with the attribute data-app-external as a normal link
    if (linkElement.getAttribute('data-app-external') === 'true') {
      return;
    }

    // Track all links that have data-app-tracking-source attribute
    const trackingSource = linkElement.getAttribute('data-app-tracking-source');
    if (trackingSource) {
      track.event({ category: 'external_link', action: trackingSource, label: decodedHref });
      return;
    }

    if (isSameOrigin(decodedHref) === false) {
      return;
    }

    // If the `href` does not match any of our routes we can assume it is an
    // external link and we should let the browser handle it.
    if (getPageDetails(decodedPathname, decodedSearch) == null) {
      debug('ignoring clicked link that does not match any of our routes');
      return;
    }

    debug('handling clicked link that matches one of our routes');
    event.preventDefault();

    const href = `${decodedPathname}${decodedSearch}`;

    // If we want a link that updates the URL without adding an entry to the history stack
    // we can use this attribute: `< href="/foobar" data-replace-history-state="true" ...`.
    if (linkElement.getAttribute('data-replace-history-state') === 'true') {
      history.replace(href);
    } else {
      history.push(href);
    }
  }

  const CurrentPageComponent = currentPage.details.component;

  return (
    <AppStateProvider
      previousPage={previousPage}
      currentPage={currentPage}
      currentPageSettings={currentPageSettings}
      setCurrentPageSettings={setCurrentPageSettings}
      isFirstRender={isFirstRender}
      isLoading={isLoading}
      history={history}
    >
      <GeolocationProvider>
        {/*
            Disable a11y eslint rules about event listeners on non-interactive elements.
            The `onClick` event handler is meant to capture all events that bubble
            so we can handle all clicks on links anywhere in the application.
          */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div className="root" onClick={handleRootClick}>
          <ErrorBoundary currentPage={currentPage} showLogo={true} resetErrorWhenNavigatingToNewPage={false}>
            <App>
              <CurrentPageComponent key={currentPage.details.key} />
            </App>
          </ErrorBoundary>
        </div>
      </GeolocationProvider>
    </AppStateProvider>
  );
};

function getPage(location: Location<any>): IPage {
  const details = getPageDetails(location.pathname, location.search);

  if (details == null) {
    throw new Error(`Unable to get page details for location`);
  }

  return {
    location,
    details
  };
}

// Helper function to load an @loadable React component.
// The promise can be aborted if the user navigates to a new page.
function loadComponent(component: LoadableComponent<object>, signal: any) {
  return new Promise<void>((resolve, reject) => {
    component
      .load()
      .then(() => {
        if (signal.aborted === false) {
          resolve();
        }
      })
      .catch(error => {
        if (signal.aborted === false) {
          reject(error);
        }
      });
  });
}

// Use the new current page id as a class name on the `html` element and remove the previous page id
function togglePageIdClassNames({ previousPageId, currentPageId }: { previousPageId: string; currentPageId: string }) {
  const html = document.documentElement;

  if (previousPageId !== currentPageId) {
    html.classList.remove(`p-${previousPageId}`);
  }

  html.classList.add(`p-${currentPageId}`);
}
