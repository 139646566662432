import { Icon } from '@nrk/yr-icons';
import { useTheme } from '../../contexts/ThemeContext/ThemeContext';
import { Text } from '../Text/Text';

export function Menu__ThemeSelector() {
  const { prefersDarkmode, setPrefersDarkmode } = useTheme();

  function changeTheme(event: React.ChangeEvent<HTMLSelectElement>) {
    const prefersDarkmode = event.target.value as 'on' | 'off' | 'auto';
    setPrefersDarkmode({ prefersDarkmode });
  }

  return (
    <div className="menu__theme-selector">
      <Icon className="menu__theme-selector-icon" id="icon-chevron-down" size={2} />
      <select
        className="menu__theme-selector-options"
        id="menu__theme-selector-options"
        onChange={evt => {
          changeTheme(evt);
        }}
        defaultValue={prefersDarkmode}
      >
        {/* TODO(tl): Hardcoding norwegian text here for now for testing. */}
        <option value="on">På</option>
        <option value="off">Av</option>
        <option value="auto">Automatisk</option>
      </select>
      <label htmlFor="menu__theme-selector-options">
        <Text size="4" weight="bold" lang="nb-no">
          Nattmodus
        </Text>
      </label>
    </div>
  );
}
