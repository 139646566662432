import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocationList } from '../../contexts/LocationListContext/LocationListContext';
import { useFetchLocation } from '../../data/locations/hooks';
import { track } from '../../lib/analytics/track';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { eventHasActiveModifiers } from '../../lib/keyboard';
import { AnimatableModal } from '../AnimatableModal/AnimatableModal';
import { Button } from '../Button/Button';
import { Heading } from '../Heading/Heading';
import { LocationRegion } from '../LocationRegion/LocationRegion';
import { Text } from '../Text/Text';
import './EditCustomLocationNameModal.scss';

interface IProps {
  locationId: string;
  trackingLabel: 'page-header' | 'location-list';
  handleClose: () => void;
}

export function EditCustomLocationNameModal(props: IProps) {
  const { locationId, trackingLabel, handleClose } = props;

  const { data: location } = useFetchLocation({ locationId: locationId });
  const customNameInput = useRef<HTMLInputElement>(null);
  const { getCustomLocationName, setCustomLocationName, deleteCustomLocationName } = useLocationList();
  const [customNameInputValue, setCustomNameInputValue] = useState<string | undefined>(
    getCustomLocationName(locationId)
  );
  const [initialCustomName] = useState(getCustomLocationName(locationId));
  const [hasChangedInputValue, setHasChangedInputValue] = useState<boolean>(false);
  const translate = useTranslate();

  const updateLocationName = useCallback(() => {
    // If there is no changes to the custom name we do nothing
    if (customNameInputValue === initialCustomName || customNameInputValue == null) {
      return;
    }

    // If the input is empty or the same as the location name, we delete the custom name
    if (/\S/.test(customNameInputValue ?? '') === false || customNameInputValue === location?.name) {
      track.event({ category: 'login', action: 'delete_custom_name', label: trackingLabel });
      deleteCustomLocationName({ locationId });
    } else {
      track.event({ category: 'login', action: 'edit_custom_name', label: trackingLabel });
      setCustomLocationName({ locationId: locationId, name: customNameInputValue });
    }
  }, [
    customNameInputValue,
    deleteCustomLocationName,
    initialCustomName,
    location?.name,
    locationId,
    setCustomLocationName,
    trackingLabel
  ]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      // Do nothing if if the key event has any active modifiers.
      // We don't want to override any potential browser shortcuts such as cmd+left/right
      if (eventHasActiveModifiers(event)) {
        return;
      }

      if (event.key === 'Enter') {
        handleClose();

        updateLocationName();
      }
    }

    document.body.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleClose, updateLocationName]);

  useEffect(() => {
    setTimeout(() => {
      if (customNameInput.current != null) {
        customNameInput.current.focus();
      }
    });
  }, []);

  if (location == null) {
    return null;
  }

  return (
    <AnimatableModal
      contentLoaded={true}
      onClose={handleClose}
      ariaLabel={translate('login/customName/modal/ariaLabel')}
      showModal={true}
      rounded={true}
      withPadding={true}
    >
      <div className="edit-custom-location-name-modal">
        <Heading size={'4'} level={'h4'} className="edit-custom-location-name-modal__header">
          {translate('login/customName/modal/header')}
        </Heading>

        <div className="edit-custom-location-name-modal__content">
          <div className="edit-custom-location-name-modal__button-container">
            <div className="edit-custom-location-name-modal__content-name-input-container">
              <input
                ref={customNameInput}
                className="edit-custom-location-name-modal__content-name-input"
                value={customNameInputValue ?? location.name}
                onChange={e => {
                  setHasChangedInputValue(true);
                  setCustomNameInputValue(e.target.value);
                }}
              />
              <Button
                as="button"
                iconAfter="icon-cross"
                buttonType="bleed"
                buttonVariant="neutral"
                onClick={() => {
                  customNameInput.current?.focus();
                  setCustomNameInputValue('');
                }}
              />
            </div>
            <Text size={'5'} className="edit-custom-location-name-modal__location-text">
              <Text size={'5'} weight={'bold'}>
                {`${location.name},`}
              </Text>
              <Text size={'5'}>
                &nbsp;
                <LocationRegion location={location} textColor={'secondary'} />
              </Text>
            </Text>
            <Button
              className="edit-custom-location-name-modal__delete-custom-name-button"
              as="button"
              iconBefore="icon-time-machine"
              disabled={customNameInputValue === location.name}
              onClick={() => {
                setCustomNameInputValue(location.name);
              }}
            >
              {translate('login/customName/modal/resetButton')}
            </Button>
          </div>
        </div>
        <div className="edit-custom-location-name-modal__toolbar">
          <Button
            as="button"
            buttonType="bleed"
            buttonVariant="neutral"
            onClick={() => {
              handleClose();
            }}
          >
            {translate('login/customName/modal/cancelButton')}
          </Button>
          <Button
            as="button"
            buttonType="default"
            disabled={hasChangedInputValue === false && customNameInputValue === initialCustomName}
            onClick={() => {
              handleClose();
              updateLocationName();
            }}
          >
            {translate('login/customName/modal/saveButton')}
          </Button>
        </div>
      </div>
    </AnimatableModal>
  );
}
