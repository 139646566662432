import { captureMessage } from '../errorReporter';

let localStorageSupported: boolean;

// When testing if storage is supported we can't just check if `getItem()` works,
// we need to actually attempt to write a value.
// See https://gist.github.com/paulirish/5558557
export function isLocalStorageSupported() {
  if (localStorageSupported == null) {
    try {
      window.localStorage.setItem('isLocalStorageSupported', 'test');
      window.localStorage.removeItem('isLocalStorageSupported');
      localStorageSupported = true;
    } catch (error) {
      localStorageSupported = false;
    }
  }

  return localStorageSupported;
}

export function setLocalStorageObject(key: string, localStorageObject: object) {
  if (isLocalStorageSupported() === false) {
    return;
  }

  try {
    const localStorageData = JSON.stringify(localStorageObject);
    window.localStorage.setItem(key, localStorageData);
  } catch (error) {
    captureMessage('Error in setLocalStorageObject(): Error writing to local storage', {
      extra: {
        key,
        error
      }
    });

    return;
  }
}

export function getLocalStorageObject<T extends any>(key: string): T | undefined {
  if (isLocalStorageSupported() === false) {
    return undefined;
  }

  const localStorageValue = window.localStorage.getItem(key);

  if (localStorageValue == null) {
    return undefined;
  }

  if (typeof localStorageValue !== 'string') {
    captureMessage('Error in getLocalStorageObject(): Local storage value is not a string', {
      extra: {
        key,
        type: typeof localStorageValue,
        localStorageValue
      }
    });

    return undefined;
  }

  try {
    const localStorageObject = JSON.parse(localStorageValue);

    if (localStorageObject == null) {
      captureMessage('Error in getLocalStorageObject(): Parsed local storage value is null', {
        extra: {
          key,
          localStorageValue
        }
      });

      return undefined;
    }

    if (typeof localStorageObject !== 'object') {
      captureMessage('Error in getLocalStorageObject(): Parsed local storage value is not an object', {
        extra: {
          key,
          localStorageValue,
          type: typeof localStorageObject
        }
      });

      return undefined;
    }

    return localStorageObject;
  } catch {
    captureMessage('Error in getLocalStorageObject(): Error parsing local storage object', {
      extra: {
        key,
        localStorageValue
      }
    });

    return undefined;
  }
}

export function setLocalStorageString(key: string, value: string) {
  if (isLocalStorageSupported() === false) {
    return;
  }

  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    return;
  }
}

export function getLocalStorageString(key: string) {
  if (isLocalStorageSupported() === false) {
    return undefined;
  }

  try {
    const localStorageString = window.localStorage.getItem(key);

    if (localStorageString == null) {
      return undefined;
    }

    return localStorageString;
  } catch {
    return undefined;
  }
}
