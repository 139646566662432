import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { createPageUrl } from '../../app/urls';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useBusyState } from '../BusyStateContext/BusyStateContext';
import { getLocationByPosition, getPosition } from './helpers/helpers';
import { initialState, reducer } from './helpers/reducer';

interface IProps {
  children: React.ReactNode;
}

interface IContext {
  startGeolocation: () => void;
  isSupported: boolean;
}

const GeolocationContext = createContext<IContext | undefined>(undefined);

export function GeolocationProvider(props: IProps) {
  const { children } = props;

  const localeCode = useLocaleCode();
  const { history } = useAppState();
  const { setBusyState } = useBusyState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isSupported, setIsSupported] = useState(true);

  const startGeolocation = useCallback(() => {
    if (state.type === 'IDLE') {
      dispatch({ type: 'GET_POSITION' });
    }
  }, [state]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    async function run() {
      if (state.type === 'IDLE') {
        setBusyState(false);
      }

      if (state.type === 'GET_POSITION') {
        setBusyState(true);

        try {
          const position = await getPosition();

          if (signal.aborted === false) {
            dispatch({
              type: 'GET_LOCATION',
              position: { lat: position.coords.latitude, lon: position.coords.longitude }
            });
          }
        } catch {
          if (signal.aborted === false) {
            dispatch({ type: 'ERROR' });
            setIsSupported(false);
          }
        }
      }

      if (state.type === 'GET_LOCATION') {
        try {
          const location = await getLocationByPosition({
            localeCode,
            position: state.position
          });

          if (signal.aborted === false) {
            if (location != null) {
              dispatch({ type: 'REDIRECT', location });
            } else {
              dispatch({ type: 'ERROR' });
            }
          }
        } catch {
          if (signal.aborted === false) {
            dispatch({ type: 'ERROR' });
          }
        }
      }

      if (state.type === 'REDIRECT') {
        history.push(
          createPageUrl({
            pageId: 'forecast',
            subpageId: 'daily-table',
            locationId: state.location.id,
            localeCode,
            urlPath: state.location.urlPath
          })
        );

        dispatch({ type: 'IDLE' });
      }

      if (state.type === 'ERROR') {
        // Fail silently
        dispatch({ type: 'IDLE' });
      }
    }

    run();

    return () => {
      abortController.abort();
    };
  }, [state, history, localeCode, setBusyState]);

  const value = useMemo(() => {
    return {
      startGeolocation,
      isSupported
    };
  }, [startGeolocation, isSupported]);

  return <GeolocationContext.Provider value={value}>{children}</GeolocationContext.Provider>;
}

export function useGeolocation() {
  const context = useContext(GeolocationContext);

  if (context === undefined) {
    throw new Error('useGeolocationContext must be used within a GeolocationContextProvider');
  }

  return context;
}
