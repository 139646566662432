import { getApiBase } from '../app/api';
import { FetchResponseError } from '../app/errors';
import settings from '../app/settings';

type TMethod = 'PUT' | 'GET' | 'POST' | 'DELETE';

export async function api<ResponseType>({
  path,
  accessToken,
  method = 'GET',
  body
}: {
  path: string;
  accessToken?: string;
  method?: TMethod;
  body?: Object;
}): Promise<ResponseType> {
  // After upgrading to node 18, msw is not properly intercepting our fetch calls correctly.
  // To circumvent this we are forcing the baseUrl to be localhost when running browser tests.
  // See github issue for msw: https://github.com/mswjs/msw/issues/1388
  const baseUrl = settings.site.isBrowserTest ? 'http://localhost:3000/api/v0' : getApiBase(path);
  const url = `${baseUrl}/${path}`;

  let response = null;

  // Include headers and body if accessToken and body are provided
  if (accessToken != null && body != null) {
    response = await fetch(url, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`
      },
      body: JSON.stringify(body)
    });
    // Include Authorization headers if accessToken are provided
  } else if (accessToken != null && body == null) {
    response = await fetch(url, {
      method,
      headers: {
        Authorization: `${accessToken}`
      }
    });
  } else {
    response = await fetch(url, { method });
  }

  if (response.ok === false) {
    throw new FetchResponseError(response.status, `Could not fetch "${url}"`);
  }

  return await response.json();
}
