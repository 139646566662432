import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useFetchUserData } from '../../data/user/hooks';
import { useUpdateLanguage, useUpdateWelcomeMessageAccepted } from '../../data/user/mutations';
import { LocaleCode } from '../../model/locale';
import { useIsAuthenticatedUser } from '../IsAuthenticatedUserProvider/IsAuthenticatedUserProvider';
import { useLogin } from '../LoginContext/LoginContext';
import { reducer } from './helpers/reducer';

interface IProps {
  children: React.ReactNode;
}

interface IContext {
  id: string | undefined;
  language: LocaleCode | undefined;
  welcomeMessageAccepted: boolean | undefined;
  name: string | undefined;
  email: string | undefined;
  hasUserData: boolean;
  updateUserLanguage: ({ language }: { language: LocaleCode }) => void;
  updateUserWelcomeMessageAccepted: ({ accepted }: { accepted: boolean }) => void;
}

const YrApiUserContext = createContext<IContext | undefined>(undefined);

export function YrApiUserProvider(props: IProps) {
  const { children } = props;

  const isAuthenticatedUser = useIsAuthenticatedUser();
  const [id, setId] = useState<string | undefined>(undefined);
  const [language, setLanguage] = useState<LocaleCode | undefined>(undefined);
  const [welcomeMessageAccepted, setWelcomeMessageAccepted] = useState<boolean | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);

  // We use the `nrk-auth-flag` cookie to make an assumption of whether we have user data or not
  // If the user is NOT authenticated we set it to true, since the user data then should be available in local storage
  // If the user IS authenticated then we initially set it to false, and wait for a response from `useFetchUserData`.
  const [hasUserData, setHasUserData] = useState(isAuthenticatedUser === true);

  const { accessToken, user, isLoggedIn } = useLogin();
  const { data: userData } = useFetchUserData({ accessToken });

  const { mutate: updateApiUserLanguage } = useUpdateLanguage();
  const { mutate: updateApiUserWelcomeMessageAccepted } = useUpdateWelcomeMessageAccepted();

  const [state, dispatch] = useReducer(reducer, { id, language, welcomeMessageAccepted, name, email });

  useEffect(() => {
    setId(userData?.user.id);
    setLanguage(userData?.user.options.language);
    setWelcomeMessageAccepted(userData?.user.options.welcomeMessageAccepted);
    setName(user?.name);
    setEmail(user?.email);

    dispatch({ type: 'INITIALIZE', id, language, welcomeMessageAccepted, name, email });
  }, [userData, state, user?.name, user?.email, id, language, welcomeMessageAccepted, name, email]);

  useEffect(() => {
    if (userData != null) {
      setHasUserData(true);
    }
  }, [userData]);

  const updateUserLanguage = useCallback(
    ({ language }: { language: LocaleCode }) => {
      if (isLoggedIn === true) {
        updateApiUserLanguage({ accessToken, language, dispatch });
      }
    },
    [accessToken, isLoggedIn, updateApiUserLanguage]
  );

  const updateUserWelcomeMessageAccepted = useCallback(
    ({ accepted }: { accepted: boolean }) => {
      if (isLoggedIn === true) {
        updateApiUserWelcomeMessageAccepted({ accessToken, accepted, dispatch });
      }
    },
    [accessToken, isLoggedIn, updateApiUserWelcomeMessageAccepted]
  );

  const value = useMemo(() => {
    return {
      id,
      language,
      welcomeMessageAccepted,
      name,
      email,
      hasUserData,
      updateUserLanguage,
      updateUserWelcomeMessageAccepted
    };
  }, [
    id,
    language,
    welcomeMessageAccepted,
    name,
    email,
    hasUserData,
    updateUserLanguage,
    updateUserWelcomeMessageAccepted
  ]);

  return <YrApiUserContext.Provider value={value}>{children}</YrApiUserContext.Provider>;
}

export function useYrApiUser() {
  const context = useContext(YrApiUserContext);
  if (__SERVER__) {
    return {
      id: undefined,
      language: undefined,
      welcomeMessageAccepted: undefined,
      name: undefined,
      email: undefined,
      hasUserData: false,
      updateUserLanguage: () => undefined,
      updateUserWelcomeMessageAccepted: () => undefined
    };
  }
  if (context === undefined) {
    throw new Error('useAuth must be used within a LoginProvider');
  }
  return context;
}
