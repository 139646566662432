import { Icon, TIconId } from '@nrk/yr-icons';
import classNames from 'classnames';
import { useId, useState } from 'react';
import { Heading } from '../Heading/Heading';
import { InfoButton } from '../InfoButton/InfoButton';
import { Text } from '../Text/Text';
import './Banner.scss';

type TInfoButton = {
  testId?: string;
  ariaLabel?: string;
  renderInfoPanel: (openedById: string, onClose: () => void) => React.ReactNode;
};

interface IProps {
  className?: string;
  type?: 'yellow' | 'purple';
  title?: string;
  iconLeft?: TIconId;
  infoButton?: TInfoButton;
  contentTestId?: string;
  children: React.ReactNode;
}

export function Banner(props: IProps) {
  const { className, type = 'yellow', title, iconLeft, infoButton, children, contentTestId } = props;

  const infoButtonId = useId();
  const [showInfoPanel, setShowInfoPanel] = useState(false);

  return (
    <div className={classNames(className, 'banner')} data-type={type} data-info-button={infoButton != null}>
      {iconLeft && <Icon className="banner__icon-left" id={iconLeft} size={3} />}
      {infoButton != null && (
        <InfoButton
          className="banner__info-button"
          id={infoButtonId}
          ariaLabel={infoButton.ariaLabel}
          data-testid={infoButton.testId}
          onClick={() => setShowInfoPanel(!showInfoPanel)}
        />
      )}
      <div className="banner__content" data-testid={contentTestId}>
        {title && (
          <Heading size="4" level="h2" className="banner__content">
            {title}
          </Heading>
        )}
        <Text size="4">{children}</Text>
      </div>

      {showInfoPanel &&
        infoButton != null &&
        infoButton.renderInfoPanel(infoButtonId, () => setShowInfoPanel(!showInfoPanel))}
    </div>
  );
}
